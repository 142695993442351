import React from "react"
import { Layout } from "../../../components/Layout";
import $ from 'jquery';
import { Helmet } from 'react-helmet';


const CustomerProtectionAgreement = () => {
    function ShowHtml() {
        $('html,body').scrollTop(0);

        return (
            <div dangerouslySetInnerHTML={{
                __html: "<html>" +

                    "<head>" +
                    "<meta charset='utf-8'>" +
                    "<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
                    "<title>Projects</title>" +
                    "<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
                    "<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
                    "rel='stylesheet'>" +
                    "<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
                    "<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
                    "<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
                    "<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
                    ".template_content{border - radius:4px;margin:80px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
                    ".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
                    "</head><body> <div class='container'>" +
                    "<div class='template'>" +
                    "<div class='template_content'>" +
                    "<br><p><strong><u><h3>LEGAL PROTECTION</h3></u></strong>" +
                    "<strong>1. </strong>The 'LEGAL PROTECTION' Agreement is intended to standardize high quality services in contracts between the customer and the enterprise. <br><br>" +
                    "<strong>2. </strong>The 'LEGAL PROTECTION' Agreement is an integral part of the user agreement.<br><br>" +
                    "<strong>3. </strong>The purpose of this Agreement is to protect the rights of Customers who sell their vehicle and rent their own or other vehicles. It is to enable the Users to establish and maintain a comfortable, simple and clear relationship throughout the entire period from the signing of the Agreement until its conclusion, in which the relations of the parties are clearly and clearly defined, their rights are mutually protected and possible conflicts are prevented, and if they cannot be prevented, they can be resolved quickly and smoothly.   <br><br>" +
                    "<strong>4. </strong>This Agreement takes precedence over any sale/lease agreement between the parties.<br><br>" +
                    "<strong>5. </strong>Acceptance of practices contrary to the articles herein in the contract between the parties does not invalidate the contract. The enterprise accepts that the customer has the right to benefit from these contract clauses.<br><br>" +
                    "<strong>6. </strong>In order for this contract to be valid, the User must have paid the Platform service fee. Otherwise, it has no legal validity.  <br><br>" +
                    "<strong>7. </strong>This Agreement shall not be considered as a legal and valid contract for dispute resolution between the Customer and the Business and shall not constitute legal evidence for the parties if the Platform service fee is not paid by the User.<br><br>" +
                    "<strong>8. </strong>During the return of the vehicle, the Business cannot charge the Customer for 'loss of value', 'wear and tear cost' or similar and similar purposes and expressions based on the cost of the vehicle and/or by comparing the same brand model with the new one on the date of return. <br><br>" +
                    "<strong>9. </strong>The Business is obliged to specify the payment plan showing the monthly rental amount in the lease agreement, date and amount. If different rental rates are applied for different periods, it must be stated based on the total average. The total average is calculated as total rent amount / total number of months = average rent amount. In case of non-payment of the monthly payments, the Management shall make a claim or refund the monthly average amount.  <br><br>" +
                    "<strong>10. </strong>If there is a condition of taking a deposit from the tenant in the rental contract, the amount, the reason for taking the deposit, the date of return must be specified. <br><br>" +
                    "<strong>11. </strong>The deposit cannot be used except for the reason specified in the lease agreement between the parties and must be returned to the lessee after the end of the contract. <br><br>" +
                    "<strong>12. </strong>If the vehicle is returned due to non-payment of the car rental fee during the rental period, the amount of compensation to be requested for the remaining period of the lease contract must be specified in the contract for each month. If, after the vehicle is returned, the vehicle is rented again to another Customer on the Platform or in another way and the new rental price is lower than the price to be paid for the remaining period, the difference between the amount rented to the new Customer may be claimed. If the Lessee fails to pay the rental price and return the vehicle, he/she shall be liable for the full rental price.<br><br>" +
                    "<strong>13. </strong>If the business requests the return of the vehicle and the cancellation of the contract, the rental fees for the next period, the price of which is received in advance, will be returned to the Customer.<br><br>" +
                    "<strong>14. </strong>If there are additional insurances required to be made / paid by the Customer (such as additional driver-young driver insurances), it is specified in the contract. Otherwise, no price can be claimed on the grounds that these insurance policies are not made by the Customer.<br><br>" +
                    "<strong>15. </strong>IN THE EVENT THAT THE COMPANY PURCHASES THE VEHICLE WITH THE FINANCING OF THE FINANCIAL LEASING COMPANY IN THE 'SELL AND LEASE BACK' TRANSACTION, THE VEHICLE LEASING AGREEMENT BETWEEN THE CUSTOMER AND THE COMPANY MAY NOT CONTAIN CLAUSES FORCING THE SALE OF THE VEHICLE TO THE PREVIOUS OWNER AND MAY NOT BE SOLD BACK TO THE PREVIOUS OWNER AT THE END OF THE LEASING PERIOD. EVEN IF THIS CLAUSE IS INCLUDED IN THE CONTRACT BETWEEN THE VEHICLE OWNER AND THE VEHICLE COMPANY, THE PARTIES AGREE THAT IT IS INVALID. <br><br>" +
                    "<strong>16. </strong>THE APPLICATION OF THIS ARTICLE IN A DIRECT LEASE AGREEMENT BETWEEN THE FINANCIAL LEASING COMPANY AND THE OWNER OF THE COMMERCIAL BUSINESS VEHICLE SHALL BE GOVERNED BY THE LAWS OF THE UNITED STATES OF AMERICA.<br><br>" +
                    "<strong>17. </strong>The company may grant additional minor damage limit and charge a fee for it. This must be specified in the rental agreement. <br><br>" +
                    "<strong>18. </strong>The Company is obliged to submit to the Customer invoices for expenses incurred by the Customer for damage incurred during the Customer's use of the vehicle. Without submitting this invoice, the damage fee cannot be demanded or collected from the tenant, and if a deposit has been taken for this purpose, it cannot be offset.<br><br>" +
                    "<strong>19. </strong>When the vehicle is delivered to the lessee, the lessee cannot be held responsible for undamaged or undamaged contents. For example, as a result of the delivery of the bottom of the vehicle without photographing the bottom of the vehicle, it does not mean that the bottom of the vehicle is delivered undamaged. If it is not stated that it is undamaged, the damage fee cannot be claimed from the user by checking under the vehicle on the return date of the vehicle. <br><br>" +
                    "<strong>20. </strong>If damage insurance is made, it should be checked that the vehicle glass and underbody are included in the policy. Otherwise, in case of such damage, the responsibility belongs to the tenant.<br><br>" +
                    "<strong>21. </strong>If the vehicle is delivered to the Customer without checking the vehicle paint with a special room and/or special light when the vehicle is delivered to the Customer, the Company cannot claim the damage fee by making a determination in this way when receiving the vehicle. In summary, the delivery method and control method of the vehicle cannot differ to the detriment of the Customer in the return of the vehicle.<br><br>" +
                    "<strong>22. </strong>Tire wear fee cannot be claimed even if the mileage of the vehicle is determined. Wear that cannot occur in the normal use of the vehicle tire is not included (For example, a tire with a life of 30,000 miles will require replacement at 15,000 miles...). <br><br>" +
                    "<strong>23. </strong>Deficiency damage cannot be claimed from the lessee on the return of the vehicle for simple mechanics that have not been checked. (For example, the bulb of the signal lamp, the bulb of the interior lighting lamp is not lit)<br><br>" +
                    "<strong>24. </strong>It is obligatory to add the screen warning lights that do not go out by starting the vehicle during the delivery of the vehicle to the delivery document.<br><br>" +
                    "<strong>25. </strong>The place and time of delivery of the vehicle must be specified in the contract.<br><br>" +
                    "<strong>26. </strong>During the delivery of the vehicle, the fuel tank must be empty/full. Vehicle fuel cost should be taken as basis for the fuel difference in vehicle return. The vehicle is delivered with a minimum of 50 USD fuel at the time of delivery and no fee can be charged due to lack of fuel at the time of return. <br><br>" +
                    "<strong>27. </strong>No fee can be charged up to 100 miles in vehicle return. <br><br>" +
                    "<strong>28. </strong>No fee can be charged for damage to the vehicle up to a limit of 100 USD.<br><br>" +
                    "<strong>29. </strong>Services such as tow truck and substitute vehicle to be provided in case of vehicle accident or breakdown must be specified in the contract with conditions and durations.<br><br>" +
                    "<strong>30. </strong>Interior and exterior photographs must be taken for the pick-up and delivery of the vehicle. The photographs of the vehicle taken at the time of delivery must be sent to the Management by the lessee.<br><br>" +
                    "<strong>31. </strong>The vehicle insurance policy must be checked and the types, conditions and limits you find missing can be completed for a fee.<br><br>" +
                    "<strong>32. </strong>Which insurances will be made for the vehicle and their amounts must be specified in the contract.<br><br>" +
                    "<strong>33. </strong>If a deposit fee is charged, the reason must be stated in the contract. The deposit return period is limited to 30 days from the date of return of the vehicle.<br><br>" +
                    "<strong>34. </strong>Seasonal tires are free of charge, the car rental company supplies them.<br><br>" +
                    "<strong>35. </strong>Additional user, additional usage amount (miles), additional usage period conditions and costs must be specified in the contract.<br><br>" +
                    "<strong>36. </strong>A report must be prepared and checked during vehicle delivery. No extra damage claim can be made after the report.<br><br>" +
                    "<strong>37. </strong>Damage assessment of plastic abrasions and rim scratches caused by the use of the vehicle must be specified in the contract. And these issues must be recorded with photographs when the vehicle is picked up.<br><br>" +
                    "<strong>38. </strong>Fuel, parking fees and all kinds of traffic fines that must be paid during the use of the vehicle will be covered by the Customer using the vehicle. <br><br>" +
                    "<strong>39. </strong>Without the knowledge and approval of the vehicle company, no changes, modifications are made to the vehicle and no additional parts are attached to the vehicle. <br><br>" +
                    "<strong>40. </strong>The traffic kit, fire extinguisher and chain equipment provided with the vehicle will be received in full during vehicle delivery and will be returned at the end of the rental period. If the equipment is missing during the return of the vehicle, the cost will be charged.<br><br>" +
                    "<strong>41. </strong>While leaving the vehicle to the service center for maintenance, breakdown or damage operations, personal belongings shall not be left in the vehicle. The car rental company cannot be held responsible for lost items.<br><br>" +
                    "<strong>42. </strong>The vehicle cannot be left running. Vehicle windows must be closed, doors and trunk must be locked.<br><br>" +
                    "<strong>43. </strong>The vehicle cannot be delivered and used by persons not specified in the rental agreement.<br><br>" +
                    "<strong>44. </strong>If the vehicle is stolen, the Police and the Management must be informed.<br><br>" +
                    "<strong>45. </strong>In case of accident, damage and malfunction, the Company must be informed.<br><br>" +
                    "<strong>46. </strong>In case of an accident, first of all, the health status of the vehicle you are in and the people in other vehicles involved in the accident should be checked. If there is a health risk, emergency notifications must be made. Then the police must be notified and then the Company must be informed.<br><br>" +
                    "<strong>47. </strong>For damage, the way the accident occurred must be recorded with the other driver/drivers involved in the accident. Photographs of the accident, vehicle license plates, vehicle documents and driver's licenses should be taken.<br><br>" +
                    "<strong>48. </strong>In an accident involving death and/or injury, the police and ambulance must be called. The vehicle must not be moved. The company must be informed.<br><br>" +
                    "<strong>49. </strong>If there is a vehicle collision or damage to the parking lot, the police must be called first and a report must be kept. If the police do not come, a statement must be made at the police station and a statement sample must be taken. In case of accident or damage, photographs should be taken.<br><br>" +
                    "<strong>50. </strong>In case of malfunction, the vehicle must be towed to a safe place. The location and malfunction must be reported to the company.<br><br>" +
                    "<strong>51. </strong>The maintenance periods specified in the user and/or warranty booklets provided by the manufacturer or distributor of the vehicle must be followed.<br><br>" +
                    "<strong>52. </strong>In case of loss of the vehicle license plate or key, the Company must be informed. A declaration must be made at the police station and a copy of the declaration must be taken.<br><br>" +
                    "<strong>53. </strong>Permission must be obtained from the Company for repairing any damage that may occur on the vehicle.<br><br>" +
                    "<strong>54. </strong>In the event of an accident, necessary measures must be taken to reduce damages and preserve evidence. Guidance from the company must be taken into account.<br><br>" +
                    "<strong>55. </strong>In the event of a collision with another vehicle and similar situations or in case of damage to persons, the Police must be notified first. Do not accept offers, loans and credits from parties to the accident. No declaration should be given to the other party insurers without the approval of the company.<br><br>" +
                    "<strong>56. </strong>If the customer will use the rented vehicle for commercial purposes, it must be specified in the contract.<br><br>" +
                    "<strong>57. </strong>If the Company offers a vehicle exchange during the rental period, the exchange can be realized with the vehicle options it offers. Unless otherwise stated in the contract, no rent increase can be requested in case of car exchange during the period. The customer is not obliged to accept the offer of a higher model vehicle. If the new vehicle is cheaper, the lessee may request a discount.<br><br>" +
                    "<strong>58. </strong>In the event that one of the warning lights is illuminated on the vehicle dashboard, the Company must be informed.<br><br>" +
                    "<strong>59. </strong>Unexpected breakdowns and emergency repair requirements must be specified in the contract by whom (insurance, car rental company, lessee, etc.).<br><br>" +
                    "<strong>60. </strong>During the return of the car, if the Customer disagrees with the findings of the inspection, this must be stated in the inspection report.<br><br>" +
                    "<strong>61. </strong>There must be a table indicating the prices and tariffs of the missing/damaged materials and extra services at the time of delivery of the vehicle. In this tariff, equipment such as vehicle key, vehicle user manual, spare tire, vehicle repair kit, suitcase fastening apparatus should be specified. <br><br>" +
                    "<strong>62. </strong>If an extra service fee will be charged for the requests of the car rental company for traffic fines, payment warnings, unpaid tolls, it should be specified in the contract.<br><br>" +
                    "<strong>63. </strong>The fees to be charged in case of smoking in the vehicle, dirty delivery of the vehicle despite the condition of clean delivery must be specified in the contract.<br><br>" +
                    "<strong>64. </strong>If a fee will be charged for deliveries after the delivery date / time, it must be specified in the contract.<br><br>" +
                    "<strong>65. </strong>In case of expiration or confiscation of the driver's license, the business must be informed immediately. <br><br>" +
                    "<strong>66. </strong>The vehicle must only be driven by another authorized person. <br><br>" +
                    "<strong>67. </strong>In case of any change in customer personal information (name, surname, bank details, address, etc.), the Company must be notified. <br><br>" +
                    "<strong>68. </strong>Fuel, highway tolls, traffic fines, cleaning, etc. expenses of the vehicle shall be borne by the Customer.<br><br>" +
                    "<strong>69. </strong>In case of exceeding mileage usage, the fee tariff must be specified in the contract.<br><br>" +
                    "<strong>70. </strong>For changes to be made to the appearance or parts of the vehicle, permission must be obtained from the Company.<br><br>" +
                    "<strong>71. </strong>In case of damage to the vehicle, the Company will make an appointment to be specified in the contract where the vehicle will be repaired. The Management shall instruct and inform the Customer for the delivery of the vehicle to the Customer after repair.<br><br>" +
                    "<strong>72. </strong>The customer's right of withdrawal and its conditions must be clearly stated in the contract. The customer may withdraw from the rental contract, but not from the sale contract, this is completely prohibited. Before the sale, the parties can withdraw from the agreement, after the sale, the parties can only withdraw from the lease agreement, not from the sale agreement, the sale transaction cannot be revoked or canceled under any circumstances.<br><br>" +
                    "<strong>73. </strong>Oil, water, delivery and return of the vehicle must be determined.<br><br>" +
                    "<strong>74. </strong>Businesses are prohibited from determining the sale and lease prices of vehicles for customers in violation of the rules of good faith. <br><br>" +
                    "<strong>75. </strong>The total rental price determined by the enterprise for long-term lease agreements cannot exceed the price that can be requested in equity for use in any case.<br><br>" +
                    "<strong>76. </strong>The total rental price demanded by the Operator may not exceed the purchase price of the vehicle offered by the Operator. The Business may not use a calculation method that would result in the Customer incurring a loss on the sale of the vehicle.<br><br>" +
                    "<strong>77. </strong>The Business is prohibited from circumventing these prohibitions directly or indirectly through fees and expense items that it will place in the contract and charge under different names.<br><br>" +
                    "<strong>78. </strong>The Business may not include provisions in the contract that restrict the Client's right to choose and decision-making authority.<br><br>" +
                    "<strong>79. </strong>These principles are designed to provide an ethical framework for the sale and lease agreements between the Client and the Business outside the Platform, to which the Platform is not a party. The obligation to control the implementation of these principles is on the User. The Platform cannot be held liable for any damages that may arise in the event that Users violate these principles.<br><br>" +
                    "</div>   </div> </div> </body> </html>"
            }} />)
    }
    return (
		<Layout>
			<Helmet>
                <title>We protect your legal rights and provide priority</title>
                <meta name="description" content="Priority according to the contracts made between the vehicle owner and the car rental company." />
			</Helmet>
            {ShowHtml()}
        </Layout>
    )
}

export default CustomerProtectionAgreement
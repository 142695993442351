import React, { Component } from "react";
import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom";
import { hotjar } from 'react-hotjar';
import "./scss/style.scss";
import Owntic_Card from "./views/pages/Owntic_Card/Owntic_Card";
import LegalNotice from "./views/pages/LegalNotice/LegalNotice";
import PaymentAssurance from "./views/pages/PaymentAssurance/PaymentAssurance";
import CustomerProtectionAgreement from "./views/pages/CustomerProtectionAgreement/CustomerProtectionAgreement";
import BonusProgram from "./views/pages/BonusProgram/BonusProgram";
//import Blog from "./views/pages/Blog/Blog";
//import BlogDetail from "./views/pages/Blog/BlogDetail";
import UserAgreement from "./views/pages/UserAgreement/UserAgreement";
import PrivacyPolicy from "./views/pages/PrivacyPolicy/PrivacyPolicy";
import PrivacyNotice from "./views/pages/PrivacyNotice/PrivacyNotice";
import About444 from "./views/pages/About444/About444";
import moment from "moment"
import { I18nextProvider } from "react-i18next"
import i18n from "./languages"
import { CryptInfo } from "./util/utils";
import CryptoJS from "crypto-js";
import FacebookPixel from 'react-facebook-pixel';
import {createMuiTheme, createTheme, ThemeProvider} from "@material-ui/core";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Home = React.lazy(() => import("./views/pages/home/Home"));
const EnterprisePage = React.lazy(() =>
    import("./views/pages/Enterprise/EnterprisePage")
);
const Calculate = React.lazy(() => import("./views/pages/Calculate/Calculate"));
const ResetPassword = React.lazy(() =>
    import("./views/pages/ResetPassword/ResetPassword")
);
const PublishAd = React.lazy(() => import("./views/pages/PublishAd/PublishAd"));
const Pricing = React.lazy(() => import("./views/pages/Pricing/Pricing"));

const CarCompanies = React.lazy(() =>
    import("./views/pages/CarCompanies/CarCompanies")
);

const OwnticBroker = React.lazy(() =>
    import("./views/pages/OwnticBroker/ownticbroker")
);

//const Newad = React.lazy(() => import('./views/pages/newad/newad'));

const Contact = React.lazy(() => import("./views/pages/Contact/Contact"));
const FAQ = React.lazy(() => import("./views/pages/FAQ/FAQ"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgotPassword = React.lazy(() =>
    import("./views/pages/ForgotPassword/ForgotPassword")
);
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const UserVerification = React.lazy(() =>
    import("./views/pages/register/UserVerification")
);

const _TechnicalPage = React.lazy(() => import("./views/pages/technicalPage/TechnicalPage") )

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUser:false
        };
    }
    componentDidMount() {
        FacebookPixel.init('184533391080744');
        FacebookPixel.pageView();

        hotjar.initialize('3786590', "6");
        //  hotjar.initialize('3174954', "6");
        let userId
        if (localStorage.getItem("user")) {
            var user = localStorage.getItem("user");
            let secret = CryptInfo.SecretKey.toString();
            var bytes = CryptoJS.AES.decrypt(user, secret);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            user = JSON.parse(originalText)
            userId = user.id;
            this.setState({isUser: userId})
            hotjar.identify(userId, { userProperty: 'value' });
        }
        
        if (!userId && window.location.pathname.includes('dashboard')) {
            window.location.href = '/'
        }

        let loginTime = localStorage.getItem("userLoginTime")
        if (loginTime) {
            if (moment().diff(parseInt(loginTime), "hours") >= 3) {
                localStorage.clear()
                sessionStorage.clear()

                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    if (name.includes("user")) {
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                }

                setTimeout(function () {
                    window.location.href = "/"
                }, 100);
            }
        } else {

        }
        if (document.getElementsByTagName("iframe").length > 0) {
            document.getElementsByTagName("iframe")[0].remove()
        }
        if (!sessionStorage.getItem("lang")) {
            sessionStorage.setItem("lang", "en")
        }
    }

    render() {
        return (
            <ThemeProvider theme={THEME}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    name="Home Page"
                                    render={(props) => <Home {...props} />}
                                />
                                <Route
                                    exact
                                    path="/technical-page/:id"
                                    name="_Technical"
                                    render={() => <_TechnicalPage />}
                                />
                                <Route
                                    exact
                                    path="/CarCompanies"
                                    name="CarCompanies"
                                    render={(props) => <CarCompanies {...props} />}
                                />
                                <Route
                                    exact
                                    path="/OwnticBroker"
                                    name="ownticbroker"
                                    render={(props) => <OwnticBroker {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Enterprise"
                                    name="Enterprise"
                                    render={(props) => <EnterprisePage {...props} />}
                                />
                                <Route
                                    exact
                                    path="/PublishAd"
                                    name="New Ad"
                                    render={(props) => <PublishAd {...props} />}
                                />
                                <Route
                                    exact
                                    path="/car-free-data"
                                    name="CarFreeData"
                                    render={(props) => <Calculate {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Pricing"
                                    name="Pricing"
                                    render={(props) => <Pricing {...props} />}
                                />
                                {/* <Route exact path="/PublishAd" name="Home Page" render={props => <PublishAd {...props} />} /> */}
                                <Route
                                    exact
                                    path="/Owntic_Card"
                                    name="Owntic_Card"
                                    render={(props) => <Owntic_Card {...props} />}
                                />
                                <Route
                                    exact
                                    path="/PaymentAssurance"
                                    name="PaymentAssurance"
                                    render={(props) => <PaymentAssurance {...props} />}
                                />
                                <Route
                                    exact
                                    path="/CustomerProtectionAgreement"
                                    name="CustomerProtectionAgreement"
                                    render={(props) => <CustomerProtectionAgreement {...props} />}
                                />
                                {/*<Route*/}
                                {/*    exact*/}
                                {/*    path="/blog"*/}
                                {/*    name="blog"*/}
                                {/*    render={(props) => <Blog {...props} />}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                {/*    exact*/}
                                {/*    path="/blog/:id"*/}
                                {/*    name="blogDetail"*/}
                                {/*    render={(props) => <BlogDetail {...props} />}*/}
                                {/*/>*/}
                                <Route
                                    exact
                                    path="/BonusProgram"
                                    name="BonusProgram"
                                    render={(props) => <BonusProgram {...props} />}
                                />
                                <Route
                                    exact
                                    path="/UserAgreement"
                                    name="UserAgreement"
                                    render={(props) => <UserAgreement {...props} />}
                                />
                                <Route
                                    exact
                                    path="/PrivacyPolicy"
                                    name="PrivacyPolicy"
                                    render={(props) => <PrivacyPolicy {...props} />}
                                />
                                <Route
                                    exact
                                    path="/PrivacyNotice"
                                    name="PrivacyNotice"
                                    render={(props) => <PrivacyNotice {...props} />}
                                />
                                <Route
                                    exact
                                    path="/About444"
                                    name="About444"
                                    render={(props) => <About444 {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Login"
                                    name="Login"
                                    render={(props) => <Login {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Register"
                                    name="Register"
                                    render={(props) => <Register {...props} />}
                                />
                                <Route
                                    exact
                                    path="/ForgotPassword"
                                    name="ForgotPassword"
                                    render={(props) => <ForgotPassword {...props} />}
                                />
                                <Route
                                    exact
                                    path="/FAQ"
                                    name="Home Page"
                                    render={(props) => <FAQ {...props} />}
                                />
                                <Route
                                    path="/ResetPassword"
                                    name="Home Page"
                                    render={(props) => <ResetPassword {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Contact"
                                    name="Contact Page"
                                    render={(props) => <Contact {...props} />}
                                />
                                <Route
                                    exact
                                    path="/500"
                                    name="Page 500"
                                    render={(props) => <Page500 {...props} />}
                                />
                                <Route
                                    path="/dashboard"
                                    name="Dashboard"
                                    render={(props) => <TheLayout {...props} />}
                                />
                                <Route
                                    path="/dashboard/MyAds"
                                    name="Dashboard"
                                    render={(props) => <TheLayout {...props} />}
                                />
                                <Route
                                    path="/userVerification/:guid"
                                    name="UserVerification"
                                    render={(props) => <UserVerification {...props} />}
                                />
                                <Route
                                    exact
                                    path="/LegalNotice"
                                    name="LegalNotice"
                                    render={(props) => <LegalNotice {...props} />}
                                />
                                <Route
                                    exact
                                    path="*"
                                    name="Page 404"
                                    render={(props) => <Page404 {...props} />}
                                />

                            </Switch>
                        </React.Suspense>
                    </BrowserRouter>
                </I18nextProvider>
            </ThemeProvider>
            
        );
    }
}

const THEME = createTheme({
    typography: {
        "fontFamily": `"Plus Jakarta Sans" , monospace`,
    }
});

export default App;

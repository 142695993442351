import React from "react"
import { Layout } from "../../../components/Layout";
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function ShowHtml() {

	return (
		<div dangerouslySetInnerHTML={{
			__html: "<html>" +

				"<head>" +
				"<meta charset='utf-8'>" +
				"<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
				"<title>Datenschutz</title>" +
				"<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
				"<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
				"rel='stylesheet'>" +
				"<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
				"<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
				"<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
				"<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
				".template_content{border - radius:4px;margin:80px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
				".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
				"</head><body> <div class='container'>" +
				"<div class='template'>" +
				"<div class='template_content'>" +
				"<br><p><strong><u><h3>PRIVACY POLICY</h3></u></strong>" +
				"<br><strong>Introduction</strong><br>" +

				"At Owntic Vehicle Solutions Inc., ('Owntic', 'we', 'us', 'us' or 'our'), we respect and value the privacy of our users ('you' or 'your'). This Privacy Notice ('Notice') explains how we collect, use, share and process users' personal information, including sensitive personal information; what rights and choices you may have with respect to your information; how you can contact us; and how we will protect your personal information if: " +
				"<br><br>" +

				"(a) visit Owntic's websites, such as <a href='https://www.owntic.com' target='_blank' >www.owntic.com</a> (our 'Sites');<br>" +
				"(b) access or use the application and other products, services and related applications offered by Owntic (collectively, the 'Service') as a customer or authorized user ('Customer') for the following purposes: <br>" +
				"(c) interact with us through our marketing activities (collectively, 'Marketing Activities'); and;<br>" +

				"This Notice does not cover the following:<br><br>" +

				"<li> Owntic employees - To learn more about our privacy practices regarding Owntic employees' personal information, please contact info@owntic.com.<br>" +
				"<li> Job applicants - For questions regarding our privacy practices for job applicants, please contact us at info@owntic.com.<br><br>" +

				"Some jurisdictions impose additional restrictions on how we process your personal information. Our practices in those jurisdictions may be more restrictive than those described in this Notice.<br>" +

				"<br><strong>About Owntic Products and Services</strong><br>" +
				"For more information about our services, please see our website at <a href='https://www.owntic.com' target='_blank'>www.owntic.com</a>.<br>" +

				"<br><strong>Quick reference</strong><br>" +
				"This Privacy Notice is organized in the following sections:<br><br>" +

				"<li> Information We Collect<br>" +
				"<li> How We Use Information<br>" +
				"<li> How We Share Information<br>" +
				"<li> Cookies and Other Tracking Technologies<br>" +
				"<li> How We Transfer and Store Information<br>" +
				"<li> How Long We Keep Information<br>" +
				"<li> How We Protect Your Information<br>" +
				"<li> Third Party Services, Applications and Websites<br>" +
				"<li> Your Privacy Choices and Rights<br>" +
				"<li> Minimum Age Requirements for Using Our Services<br>" +
				"<li> Changes to this Privacy Notice<br>" +
				"<li> Contact us<br>" +

				"<br><strong><u>Information We Collect</u></strong><br>" +
				"<br><strong>Personal information</strong><br>" +
				"Personal information is any information that identifies, relates to, describes, can reasonably be associated with, or can reasonably be linked, directly or indirectly, to a particular consumer or household.The information we collect depends on the context of your interactions with us and the choices you make(including your privacy settings), the products and features you use, your location, and applicable laws.In some cases, we receive information such as your name and email address directly from you when you register for our Services.In other cases, we receive information through your use of our Services, such as using the Owntic website.<br>" +

				"<br><strong>We collect the following categories of information</strong><br>" +
				"<li> Personal identifiers - We collect your name, phone number, email address, delivery and billing addresses, login information and IP address.<br>" +
				"<li> Internet or other network activity information - We collect browsing and search history, information collected from cookies and similar technologies, and analytics data.<br>" +
				"<li> Other inferences from personal information - We draw inferences from the categories of information collected to understand consumer preferences, characteristics or predispositions.<br>" +

				"<br><strong>Publicly Available Information</strong><br>" +
				"Personal information does not include publicly available information. For purposes of this paragraph, 'publicly available' means information that is lawfully made available from federal, state or local government records.<br>" +

				"<br><strong>Sensitive Personal Information</strong><br>" +
				"Sensitive personal information (SPI) is a category of personal information that requires a high level of protection and includes data such as financial account login credentials or health data. Personal information does not include anonymous, de-identified or aggregated data (such as summary statistics or metrics). We collect sensitive personal information directly from you and about your use of the Owntic site, including when you apply for and use our products and services. We collect information from your computer, mobile application or other access device that is automatically reported by your browser or device. The sensitive personal information we collect includes race and precise geographic location. We may also collect information about you from third parties, such as credit card processors.<br>" +

				"<br><strong>Consent to Sensitive Information</strong><br>" +
				"Depending on where you are located, you may have certain rights in connection with your personal information that we obtain. These rights vary depending on your jurisdiction. If you are a resident of Virginia or Colorado, we will not process your sensitive personal information without your consent. For the purposes of this section, sensitive information includes race and precise geographic location.<br>" +

				"<br><strong>A. Information You Provide Directly to Us</strong><br>" +
				"We collect the following information through your use of our Sites and Services.<br><br>" +

				"<li> Contact information - We collect your contact information when you use our Services. This information includes your full name, address, phone number and e-mail address.<br>" +
				"<li> Credentials -We collect your first and last name, contact information through our Application. <br>" +
				"<li> Account information - We collect information about you and your Owntic account, including your username, user ID, password, payment information and billing address. You provide this information to us directly during the account registration process. We automatically receive this information from the app the first time you log in or perform account transactions in the app. <br>" +
				"<li> Vehicle and Title Information - We collect or receive from third parties vehicle information that you provide to us directly when you use our Services. This information includes the vehicle chassis number, mileage, color, make, model, year, condition of the vehicle, vehicle title and registration. <br>" +
				"<li> Marketing information - When you participate in our Marketing Activities, we collect information provided by you, including form submissions, email communications or phone calls, to receive information about Owntic and our Services. We also collect information you provide to us when you participate in a survey, interact with us virtually or in person at an event, or have a telephone conversation with one of our sales or customer support representatives (for example, the nature of your communication, your preferences, and the information you choose to provide to us when filling out the 'free text' boxes on our forms).<br>" +
				"<li> Social media information - We use social media features such as the Twitter 'Tweet' button and other sharing tools ('Social Media Features') and receive information about you if Social Media Features are hosted directly on Our Site. If Social Media Features are hosted by a social media network and you click on them from our Site, the social media network may receive information indicating that you have visited our Site. Your interactions with Social Media Features are subject to the privacy and user policies of the companies that provide them, and we are not responsible for how those companies collect, use or disclose your information. We encourage you to review the privacy policies of these companies before connecting to or using their Social Media Features to learn more about their privacy practices. <br>" +
				"<li> Troubleshooting and support data - We collect information about your account preferences or data you provide when you contact Owntic for assistance, such as the solution you use, contact or authentication data, the content of chats and other communications with Owntic, and other details that will help us provide support.<br>" +
				"<li> When you submit a question through our support portal (for example, the content or attachments of a message you send us) or provide feedback about your experience with our Services, any information you voluntarily provide to us.<br><br>" +

				"<strong>B. Information We Collect from Third Parties</strong><br>" +
				"We receive information about you from third parties who support Owntic's business activities or provide services. We limit our use of your personal information to the purposes described in this Notice. The personal information we receive from third parties includes:<br><br>" +

				"<li> Contact information; <br>" +
				"<li> Financial transaction information;<br>" +
				"<li> Vehicle and title information.<br><br>" +

				"<strong>C. Information We Collect Automatically</strong><br>" +
				"When you use or interact with the Services, we automatically collect or receive certain information about your device and your use of our Sites and Services. This information includes:<br><br>" +

				"Usage information - Browser type and settings, usage details (e.g., timestamps, frequency), usage pattern, language preferences and device activity information(e.g., system activity and hardware settings), time spent in the mobile app or on our Sites, and information about how you interact with our Services, Marketing Activities and Sites(e.g., clicks, scrolls, hovers, internal links, pages viewed, searches, page response times, download errors and date / time stamps associated with your usage).<br><br>" +
				"Device information - IP address, location, device type, operating system, Internet service provider, system configuration information, model, model number, push notification tokens, unique device identifiers(e.g., your username and password), web browser, request information(e.g., speed, frequency), the site from which you linked to us('referring page'), the name of the website you choose to visit immediately after ours('exit page'), other websites you have recently visited, and information about the web browser you use.<br><br>" +
				"Anonymized Statistical information - In order to inform our Marketing Activities and customer experience, we collect anonymized statistical information, such as de - identified demographic or location information, information about the devices used to access our Services, or other aggregated, de - identified information about how you use our Services, such as account creation processes.<br><br>" +

				"<strong>How We Use Information</strong><br>" +
				"Owntic uses the information collected in the following ways:<br><br>" +

				"Communicate with you about our Services - We use your personal information to communicate with you about your access to and use of our Services, including sending you technical notices, updates, security alerts, and support and administrative messages.<br><br>" +
				"Provide our Sites and Services - We use your information to operate and administer our Sites and to provide, operate, monitor and maintain our Services.<br><br>" +
				"Provide and improve necessary functionality - We use your information to provide the necessary functionality required during your use of our Sites and Services.We also analyze how you use our Sites and Services to deliver the Sites and Services and to improve functionality, quality and user experience.<br><br>" +
				"Transactional considerations - We use your information to complete transactions and send you relevant information, including purchase confirmations and delivery updates.<br><br>" +
				"Handle contact and support requests - We use your information to fulfill your requests and communicate with you via email or our app chat feature.We also process your personal information to respond to your comments, questions and requests and to provide customer service and support via email, chat or phone.<br><br>" +
				"Develop and improve our Marketing Activities and Services - We use your personal information to study and analyze trends, usage and interactions with our Services, Sites, and to perform other Marketing Activities to personalize and improve our Marketing Activities and Services.We also use your information to provide content and / or features that match your interests and preferences or otherwise customize our Marketing Activities and your experience with the Services.To learn more about how we track and use your information, see our Cookie Notice.<br><br>" +
				"Send marketing communications - We use your personal information for marketing purposes according to your preferences, such as contacting you via email, SMS, features, surveys, direct mail, newsletters, promotions, trainings or events we think may be of interest to you and / or to provide you with other news or information about Owntic and / or our selected partners.Please see 'Your Privacy Preferences and Rights' below to learn how to manage your communication preferences.<br><br>" +
				"Enhance the security of our Sites and Services - We use your information to investigate and prevent fraudulent transactions, unauthorized access to the Services and other illegal activities.<br><br>" +
				"Legal, Regulatory, Security and Compliance purposes - We process your information to comply with applicable laws or regulations and to review compliance with Owntic terms of use.<br><br>" +
				"Other purposes - We process your information for other purposes for which we have previously notified you or provided notice or obtained your consent(such as in the event of a sale, merger or acquisition).<br><br>" +

				"<strong>How We Share Information</strong><br>" +
				"Owntic shares your personal information with service providers such as suppliers, vendors, advertising partners and consultants to operate our business and provide you with our Sites and Services. We partner with third parties to assist us in our marketing efforts. These third-party partners may use technologies such as cookies and other tracking technologies to collect information about your activities on our Site in order to provide our Services to you. We may share your information for a number of business and commercial purposes, including to help operate, improve or protect the services we provide and to develop new services. In particular, we share your information with our transaction processing solutions; vehicle valuation and authentication solutions; customer relationship management platform; and other organizations as permitted by law.<br><br>" +

				"<strong>A. Third Party Service Providers</strong><br>" +
				"We share your information with third party service providers who need access to your information to support our operations and delivery of the Services. The third parties with whom Owntic shares your information include:<br><br>" +

				"<li> Billing and payment providers to authorize, record, close and clear transactions;<br>" +
				"<li> Cloud hosting providers to provide data storage and processing services;<br>" +
				"<li> Communication providers to process new inquiries and manage our emails;<br>" +
				"<li> Title deed processing companies to perform lien checks and cash vehicle title deeds;<br>" +
				"<li> Enterprise services to streamline business operations and user communications;<br>" +
				"<li> Customer support services to assist with credit account management, such as dispute resolution and onboarding questions;<br>" +
				"<li> Identity verification providers to conduct anti- money laundering and credit risk checks;<br>" +
				"<li> Credit card processors to manage credit cards and provide customer support;<br>" +
				"<li> Vehicle valuation and research companies to assess vehicle value;<br>" +
				"<li> Third-party conversion companies to help convert potential leads in the event of credit card decline;<br>" +
				"<li> Insurance Providers for Marketing Activities;<br>" +
				"<li> Analytics companies to analyze our Marketing Activities and Services.<br><br>" +

				"<strong>B. Legal or Public Authorities</strong><br>" +
				"We only disclose your personal information in the following circumstances:<br><br>" +

				"<li> Reasonably necessary to comply with any applicable law or regulation;<br>" +
				"<li> Required by law to comply with a legal process or government request;<br>" +
				"<li> Necessary for the enforcement of our agreements and this Notice;<br>" +
				"<li> Necessary to protect the security or integrity of our Sites and Services;<br>" +
				"<li> Necessary to protect against harm to the rights, property or safety of Owntic or the public, to the extent required or permitted by law;<br>" +
				"<li> Necessary to respond to an emergency that we believe in good faith requires us to disclose information that will help prevent death or serious bodily injury to any person; or Otherwise, it will be directed by you.<br>" +
				"<li> Where required or permitted by applicable law, Owntic will protect personal information by seeking protective orders or confidentiality agreements, removing personal information from documents prior to production or disclosure, or by other legally permissible means.<br><br>" +

				"<strong>C. Corporate Subsidiaries</strong><br>" +
				"We may share or transfer your information with any person or entity that directly or indirectly controls, is controlled by, or is under common control with Owntic ('Corporate Affiliate') in connection with, or during negotiations relating to, any merger, sale, financing of Company assets or transfer of all or substantially all of our business to another company. Financing of Company assets or the transfer of all or part of our business to another company. Any information we provide to our Corporate Affiliates about you will be handled by those Corporate Affiliates in accordance with the terms of this Notice. Your personal information may be part of the transferred assets. You may then be notified of such change of ownership or control through the email address associated with your account.<br><br>" +

				"<strong>D. Corporate Affiliates</strong><br>" +
				"We work with various partners, such as third-party advertising networks, driver distribution networks and integration services partners. We may share personal information with them to support our Services, including user contact information regarding behavioral information for analytics purposes and to conduct Marketing Activities. For more information on how you can turn off tracking technologies for our advertising partners, please see our Cookie Notice.<br><br>" +

				"<strong>Cookies and Other Tracking Technologies</strong><br>" +
				"When you visit our Sites or interact with our Marketing Activities, we use cookies and similar technologies such as web beacons, tags and JavaScript, alone or in combination with cookies, to automatically collect certain technical information from your browser or device. For more information about how we use cookies and to learn how to manage cookies, please see our Cookie Notice.<br><br>" +

				"<strong>How We Transfer and Store Information</strong><br>" +
				"We take reasonable technical measures to store the information we have about you and to keep your information secure. In order to provide our Services, Owntic may transfer, process or store your personal information outside of your country of residence, which may have different data protection laws than the country where you originally provided the information. To the extent required by applicable law, we take appropriate steps to protect your information when we transfer it.<br><br>" +

				"<strong>How Long We Keep Information</strong><br>" +
				"Owntic retains your information for the minimum period necessary to fulfill our legal and contractual obligations, support business operations; improve the Sites and Services; resolve disputes; and enforce our rights for the legitimate business purposes set forth in this Statement and as recommended by industry standards. The criteria we use to determine the retention period are: 1) the amount and type of personal information; 2) our reasons for processing the personal information; 3) the potential risk of harm from unauthorized use or disclosure of the personal information; 4) whether we need that particular personal information to achieve our purposes; and 5) the legal requirements applicable to the personal information.<br><br>" +

				"<strong>How We Protect Your Information</strong><br>" +
				"We use reasonable and appropriate administrative, technical and physical safeguards to protect the information we have about you from loss, theft and unauthorized use, access, alteration or destruction. We also require third-party service providers acting on our behalf or with whom we share your information to maintain security measures in accordance with industry standards. If, despite our security measures, you have any questions about the security of our Sites and Services, please contact us as described in the 'Contact Us' section. For your own protection, please do not send us any confidential personal information via email or outside of our Services.<br><br>" +

				"<strong>Owntic Employee Access and Processing</strong><br>" +
				"Owntic limits employee access to Consumers' Personal Information to those employees who need to know such information to perform their job duties. Therefore, employees should consider whether the information they share internally or with our third-party service providers is necessary to perform a function or complete a task. All conversations involving any Consumer Personal Information should, if appropriate, be conducted carefully to prevent unauthorized persons from overhearing or interfering with such conversations. When in doubt, employees should refrain from sharing information and seek advice. The prohibition on disclosure of Consumers' Personal Information continues even after termination of employment with Owntic.<br><br>" +

				"<strong>Third Party Services, Applications and Websites</strong><br>" +
				"Our Sites and Services may contain links to other websites that are not operated or controlled by Owntic. Certain third-party services, applications or websites used to navigate to and from our Sites and Services have separate user terms and privacy notices that are independent of this Notice.<br><br>" +

				"Owntic requires third-party service providers acting on our behalf or with whom we share your information to provide appropriate security measures, privacy and security obligations and other appropriate privacy and security measures in accordance with industry standards and in accordance with this Notice. However, we are not responsible for the content, accuracy or opinions expressed on such sites or for the privacy and data security practices of third parties other than the personal information we receive or transmit from them. Such websites are not investigated, monitored or checked for accuracy or completeness by us.<br><br>" +

				"We encourage you to carefully review the terms and privacy statements of each third party service, website and/or application before using it.<br><br>" +

				"<strong>Your Privacy Preferences and Rights</strong><br>" +
				"<strong>A. Privacy Options</strong><br>" +
				"You have certain choices and rights about how we collect and use your personal information. Below is a summary of these choices and rights and how to exercise them.<br><br>" +

				"Opt-out of marketing communications : You may opt out of receiving any marketing communications from us in the following ways:<br>" +
				"<li> Email : Contact us as indicated in our 'Contact Us' section to unsubscribe.If you are a customer and opt out of receiving marketing communications, you will continue to receive transactional communications from us regarding our Services.<br>" +
				"<li> Direct mail: Follow the opt- out process described in direct mail communications or contact us directly as set out in our 'Contact Us' section.Push notifications: You can opt out of receiving push notifications on your mobile device in your device settings.Please note that opting out of receiving push notifications may affect the operation of our Services.<br><br>" +

				"Account deletion : If you stop using our Services, we may delete your information upon request. Please note that there may be some delay in deleting this information from our servers and backup storage may occur if necessary to comply with our legal obligations, resolve disputes, manage security risks, or enforce our agreements.<br>" +

				"Managing Cookies and Other Tracking Technologies: The relevant browser-based cookie controls are described in our Cookie Notice. Our Services participate in the Network Advertising Initiative ('NAI') and Digital Advertising Alliance ('DAA') programs to opt-out of personalized or targeted advertising online. If you enroll in either of these programs, you will not see our targeted advertising on other websites based on your browsing history or other online activity. To learn more about preference-based advertising, change your preferences or opt-out, visit<br>" +
				"<li> NAI opt- out: <a href='https://optout.networkadvertising.org/?c=1' target='_blank'>https://optout.networkadvertising.org</a>  <br>" +
				"<li> Exclusion from DAA: <a href='https://optout.aboutads.info/?c=2' target='_blank'>https://optout.aboutads.info</a><br><br>" +

				"Do Not Track: Some browsers have a 'Do Not Track' (DNT) setting that can send a signal to websites you visit that you do not want to be tracked. There is no standard for how a website interprets a DNT signal. Because there is no standard, our websites do not respond to browser DNT signals. To control how we collect and use your data, please use other tools we describe here, such as cookie controls, rather than relying on DNT settings.<br><br>" +

				"<strong>B. Privacy Rights</strong><br>" +
				"Depending on where you are located, you may have certain rights in connection with your personal information that we obtain. These rights vary depending on your jurisdiction.<br><br>" +

				"Right to Know: You have the right to know the categories and specific pieces of your personal information we have collected in the last 12 months.<br>" +
				"Right of Access: You have the right to request access to and receive information about the personal information we have collected about you.This right includes the right to access specific pieces and categories of personal information we have collected about you, the categories of sources from which the personal information was collected, the purposes for which the personal information was collected, shared, sold or processed, and the categories of personal information we share.The categories of service providers, contractors or third parties with whom we share, sell or disclose personal information.You have the right to receive this information in a structured, machine- readable format.<br>" +
				"Right to Correction: You have the right to have your Personal Information corrected if it is inaccurate or incomplete.<br>" +
				"Right to Erasure: You have the right to request erasure of any of your personal information that we have collected.<br>" +
				"Right to Request Information: You have the right to request information regarding the collection, sale and disclosure of your personal information for the last 12 months.<br>" +
				"The right to limit the use or disclosure of sensitive personal information: You have the right to request that we limit the use or disclosure of sensitive personal information.<br>" +
				"Right to Opt Out of Automated Decision Making: You have the right to object to the processing of personal information for automated decision - making purposes.<br>" +
				"Right to Opt - Out of the Sale of Information: You have the right to opt - out of the sale of personal information we collect about you.<br>" +
				"Right to Non - Discrimination: You have the right not to be subjected to discriminatory treatment when exercising your privacy rights.We do not treat anyone, including our users, differently because of the exercise of any of the rights described above.<br>" +
				"Right to Object: If we deny your privacy request, you have the right to object by emailing info@owntic.com.<br><br>" +

				"<strong>Exercising Your Rights</strong><br>" +
				"If you wish to exercise any of the above rights, see the 'Contact Us' section of this Notice. You may also authorize a person to submit a verifiable consumer request for your personal information.<br>" +
				"If you want to use an authorized agent to make an opt-out request on your behalf, you must provide written authorization signed by the authorized agent, i.e., the consumer. If the agent cannot provide Owntic with your signed authorization showing that the agent has the authority to act on your behalf, we may reject the authorized agent's request. We will fulfill requests within 45 days of receiving your request.<br><br>" +

				"<strong>We're bringing</strong><br>" +
				"Please note that your request may be limited in certain circumstances, for example, if complying with your request would conflict with<br><br>" +

				"Federal, state or local laws;<br>" +
				"Regulatory questions<br>" +
				"Subpoenas; or<br>" +
				"Exercising or defending legal claims.<br><br>" +

				"<strong>Verification of Your Identity</strong><br>" +
				"We verify your request using your email address. If you have created an account with us, we also verify your claim using information associated with your account, including billing information. Government identification may be required. If we cannot verify your identity and/or authorization to make a request on behalf of someone else, and if we cannot verify personal information about you, we cannot respond to your request. Making a verifiable consumer request does not require you to create an account with us. If we cannot verify your identity, we may refuse your request to protect your data.<br><br>" +

				"<strong>Minimum to Use Our Services</strong><br>" +
				"The Owntic Sites and Services are not directed to children under the age of 16. To use the Owntic Sites and Services, you must be old enough to consent to the processing of your personal information in your jurisdiction. We do not knowingly collect personal information from anyone under the age of 16. If you are a parent or guardian and you know that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from anyone under the age of 16 without verification or parental consent, we will take steps to remove such information.<br><br>" +

				"<strong>Changes to our Privacy Notice</strong><br>" +
				"We periodically review and update this Notice to describe new Services or changes to our practices. You can determine when this Notice was last revised by looking for the 'Update' date at the top of this Notice. We encourage you to review the Notice each time you interact with us to stay informed about our privacy practices and ways you can help protect your privacy. If we make material changes to this Notice, if you do not agree with the privacy practices described in the Notice, stop using our Sites and Services.<br><br>" +

				"<strong>Contact Us</strong><br>" +
				"To submit questions about this Notice or to update or request changes to your personal information, please contact us at info@owntic.com. Call us at +1 (559) 527-0124 or write to us:<br><br>" +

				"Owntic Vehicle Solutions Inc.<br>" +
				"8 The Green STE D Dover, DE 19901<br>" +
				"USA<br><br>" +


				"</div>   </div> </div> </body> </html>"
		}} />)
}

export const PrivacyPolicyComponent = () => {
	return (
		<>
			{ShowHtml()}
		</>
	)
}

const PrivacyPolicy = () => {
	
    return (
		<Layout>
			<Helmet>
				<title>The protection and privacy of your data</title>
				<meta name="description" content="Your data is very important to us. Therefore click for details. " />
			</Helmet>
            {ShowHtml()}
        </Layout>
    )
}

export default PrivacyPolicy
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import tr from "./tr.json"
import en from "./en.json"
import de from "./de.json"

i18n
    .use(LanguageDetector)
    .init({
        lng: sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "en",
        debug: true,
        fallbackLng: sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translations: en,
            },
            tr: {
                translations: tr,
            },
            de: {
                translations: de,
            },
        },
        ns: ["translations"],
        defaultNS: "translations",
        react: {
            wait: true,
        },
    })

export default i18n

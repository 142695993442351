import React, {createRef} from "react"
import { Layout } from "../../../components/Layout";
import "./Owntic_Card.scss";
import { Helmet } from 'react-helmet';
import {CButton, CModal, CModalBody, CModalFooter} from "@coreui/react";
import ReactPlayer from 'react-player/lazy'


const isMobile = (window.innerWidth <= 800)

const _url = sessionStorage.getItem('lang') === "en" ? "https://player.vimeo.com/video/690882884?h=c1a3df7b86&byline=0" : "https://player.vimeo.com/video/707793021?h=9647360918&byline=0"
class Owntic_Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideo: false,
            playing: false,
            url: _url
        };
        
        console.log(_url)
    }

    ShowHtml() {
        return (
            <div dangerouslySetInnerHTML={{
                __html: "<html>" +

                    "<head>" +
                    "<meta charset='utf-8'>" +
                    "<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
                    "<title>Projects</title>" +
                    "<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
                    "<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
                    "rel='stylesheet'>" +
                    "<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
                    "<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
                    "<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
                    "<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
                    ".template_content{border - radius:4px;margin:80px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
                    ".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
                    "</head><body> <div class='container'>" +
                    "<div class='template'>" +
                    "<div class='template_content'>" +
                    "<br><p><strong><u><h3>CARD OWNTIC</h3></u></strong>" +
               //     "<iframe src=" + this.state.url + " width='640' height='360' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' allowfullscreen></iframe>\n" +
                    "<p></p>" +
               //   "<p><a href='https://vimeo.com/690882884' target='_blank'>CARD OWNTIC - EN</a> from <a href='https://vimeo.com/user168456909' target='_blank'>444CAR - EN</a> on <a href='https://vimeo.com' target='_blank'>Vimeo</a>.</p>" +
                    "<strong>1) </strong>This Agreement is an integral part of the User Agreement.<br><br>" +
                    "<strong>2) </strong>In this Agreement, the definition of 'Customer' and 'Business' is the same as in the 'User Agreement'.<br><br>" +
                    "<strong>3) </strong>This Agreement takes precedence over any other agreement between the parties.<br><br>" +
                    "<strong>4) </strong>The Platform service fee for the use of CARD OWNTIC must be paid by the Customer and the Business.<br><br>" +
                    "<strong>5)	</strong>Acceptance of practices contrary to the articles herein in the contract between the parties does not invalidate the contract. The Business accepts that the Customer has the right to benefit from these contractual clauses.<br><br>" +
                    "<strong>6) </strong>This Agreement shall not be deemed a legal and valid contract or constitute legal evidence for dispute resolution between the Customer and the Business, unless the Platform service fee is paid by the Customer and the Business.<br><br>" +
                    "<strong>7) </strong>The Platform uses a lending system for 'Road Fuel Assistance' via CARD OWNTIC for the Customer and the Business.<br><br>" +
                    "<strong>8)	</strong>The customer must have paid the vehicle lease payments without delay according to the lease agreement with the Operator.<br><br>" +
                    "<strong>9) </strong>The fuel borrowing limit is limited to a maximum of 50 USD at a time.<br><br>" +
                    "<strong>10)	</strong>There is no card fee, interest, charge, late fee, annual fee, etc. in fuel borrowing.<br><br>" +
                    "<strong>11)	</strong>CARD OWNTIC debt balance must be paid to the Platform account no later than the 10th day of each month.<br><br>" +
                    "<strong>12)	</strong>CARD OWNTIC will be reopened for Customer use after the debt balance is paid.<br><br>" +
                    "<strong>13)	</strong>The fuel loan system is limited to 12 months. CARD OWNTIC will continue to provide fuel company promotions, discounts, and point redemptions, if any, by identifying them on the vehicle license plate.<br><br>" +
                    "<strong>14)	</strong>For CARD OWNTIC use, the Customer and the Operator must approve the rental period on the Platform.<br><br>" +
                    "<strong>15)	</strong>If the amount lent for fuel purchases via CARD OWNTIC is not returned by the end of the rental period, the Platform will be credited by the Customer. <br><br>" +
                    "<strong>16)	</strong>The Platform may transfer the amount due from the Customer to the Business with which the Customer has entered into a lease agreement at the end of the lease period, and it may be collected by the Business.<br><br>" +
                    "<strong>17)	</strong>The Platform reserves its rights whether or not to issue CARD OWNTIC, whether or not to allow the use of the road fuel loan system, and whether or not to change the amount, duration, cancellation and conditions thereof.<br><br>" +
                    "</div>   </div> </div> </body> </html>'"
            }} />)
    }
      render() {
        return(
            <Layout>
                <Helmet>
                    <title>Fuel Help at the Roadside</title>
                    <meta name="description" content="There are no payments relating to card fees, interest, interest on arrears, annual card payments, etc." />
                </Helmet>
                {/*<iframe
                    src={_url}
                    width="640" height="360" frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                <CModal style={{ width: "680px" }} show={this.state.showVideo}
                        onClose={() => {
                            this.setState({showVideo: false, playing: false, url: ""})

                        }}>
                    <CModalBody>
                        <div id="modalVideo" style={{margin: "auto"}}>
                            <ReactPlayer stopOnUnmount controls url={this.state.url} />
                        </div>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => {
                            this.setState({showVideo: false, playing: false, url: ""})

                        }}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>
                */}
                {this.ShowHtml()}
            </Layout>
        )
          
      } 
}
         

export default Owntic_Card
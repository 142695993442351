import React from "react"
import { Layout } from "../../../components/Layout";
import "./PaymentAssurance.scss";
import $ from 'jquery';
import { Helmet } from 'react-helmet';



const PaymentAssurance = () => {



    function ShowHtml() {

        $('html,body').scrollTop(0);

        return(
            <div dangerouslySetInnerHTML={{
                __html: "<html>" +
                    "<head>" +
                    "<meta charset='utf-8'>" +
                    "<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
                    "<title>Projects</title>" +
                    "<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
                    "<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
                    "rel='stylesheet'>" +
                    "<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
                    "<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
                    "<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
                    "<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
                    ".template_content{border - radius:4px;margin:80px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
                    ".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
                    "</head><body> <div class='container'>" +
                    "<div class='template'>" +
                    "<div class='template_content'>" +
                    "<br><p><strong><u><h3>PAYMENT ASSURANCE</h3></u></strong>" +
                    "<strong>1- </strong>This Agreement is an integral part of the User Agreement.<br><br>" +
                    "<strong>2- </strong>In this Agreement, the definition of 'Customer' and 'Business' is the same as in the 'User Agreement'.<br><br>" +
                    "<strong>3- </strong>The purpose of this Agreement is to protect the rights of the Customer who sells his/her vehicle and rents his/her own vehicle or other vehicle.<br><br>" +
                    "<strong>4- </strong>This Agreement takes precedence over the rental agreement between the parties.<br><br>" +
                    "<strong>5- </strong>Acceptance of practices contrary to the articles herein in the contract between the parties does not invalidate the contract. The Company accepts that the Customer has the right to benefit from these contractual clauses.<br><br>" +
                    "<strong>6- </strong>This Agreement is not considered a legal and valid contract and does not constitute legal evidence for dispute resolution between the Customer and the Business, unless the Platform service fee is paid by the Customer and the Business.<br><br>" +
                    "<strong>7- </strong>The Platform guarantees rent payment to the Business on behalf of the Client.<br><br>" +
                    "<strong>8- </strong>No refund is requested from the Client for the rent assurance payment made on behalf of the Client.<br><br>" +
                    "<strong>9- </strong>The Platform makes the rent assurance payment on behalf of the Client even if the Business does not accept the delay in rent payment.<br><br>" +
                    "<strong>10- </strong>For those who sell their own car and rent their own car, the rental period is at least 12 months.<br><br>" +
                    "<strong>11- </strong>For those who sell their own car and rent their own car, the rental assurance validity period is valid between the 4th-5th-6th months. The customer must not have returned the vehicle for 9 months. <br><br>" +
                    "<strong>12- </strong>Those who sell their own vehicle and rent their own car must not be late on the payment date specified in the contract with the Enterprise for the first 3 months.<br><br>" +
                    "<strong>13- </strong>For those who sell their own vehicle and rent the other vehicle, there should be no delay in the payment date specified in the contract with the Enterprise for the rental payments for 3 months in 6-month leases. In a 6-month lease, the rent assurance is only valid for the 4th month. There should be no refund.<br><br>" +
                    "<strong>14- </strong>For those who sell their own vehicle and rent the other vehicle, the Customer must not have returned the vehicle for 9 months in 9-month leases. <br><br>" +
                    "<strong>15- </strong>For those who sell their own vehicle and rent the other vehicle for 12 months and longer, the rental payments for the first 3 months must have been made without delay within the payment period specified in the contract with the Enterprise where the vehicle is rented. In this case, the rental assurance validity period is valid between the 4th-5th-6th months. The customer must not have returned the vehicle for 9 months. <br><br>" +
                    "<strong>16- </strong>In order for the lease guarantee to be paid to the Company, it must be transferred to the law firm, receivables management/collection company contracted by OWNTIC Platform for the collection of all receivables arising from the sale and lease contract. The Platform may request that customer lease payments be made through the Platform.<br><br>" +
                    "<strong>17- </strong>In order for the receivables management/collection company contracted by the OWNTIC Platform to be able to pay the OWNTIC RENT GUARANTEE on past payments, past payment records must have been made through the bank, and the Platform must give its approval for the Platform to be notified of compliance with the conditions.<br><br>" +
                    "<strong>18- </strong>In order to use the OWNTIC RENT GUARANTEE, the Platform service fee must be paid separately and in full by the Customer and the Business<br><br>" +
                    "<strong>19- </strong>The car rental company cannot collect the rent assurance fee paid by OWNTIC. Otherwise, it must return it to the Platform.<br><br>" +
                    "<strong>20- </strong>OWNTIC's Rent Assurance will continue under the conditions set forth herein even if a receivables insurance is in place.<br><br>" +
                    "<strong>21- </strong>No rent assurance payment will be made for the months in which the vehicle is returned.<br><br>" +
                    "<strong>22- </strong>In the event that there is a dispute between the Customer and the Company and the situation is resolved through the judicial system, it is pending the outcome of the dispute and will be valid accordingly.<br><br>" +
                    "<strong>23- </strong>If the rental assurance amount is more than the Platform service fee, the remaining part is deducted from the Platform service fee that the Business must pay for the transactions to be performed on the Platform. The Business cannot make a claim from the Customer for this difference.<br><br>" +
                    "<strong>24- </strong>The rental guarantee is valid for the period (1 month) following the period of delay (1 month) in case the Customer fails to make payment for the following period (1 month).<br><br>" +
                    "<strong>25- </strong>If the Customer has a debt balance of CARD OWNTIC - USD 50, it will be deducted from the rent assurance and paid to the Company.<br><br>" +
                    "<strong>26- </strong>OWNTIC reserves the right to grant or deny this guarantee, and to change the amount, duration, cancellation and conditions.<br><br>" +
                    "<strong>27- </strong>OWNTIC RENT ASSURANCE does not constitute a surety or guarantor.<br><br>" +
                    "<strong>28- </strong>In the event that the Customer postpones the payment for 30 days by accepting the Platform's payment guarantee, or in the event that the Customer fails to pay despite the 30-day postponement, neither the Customer nor the Platform may claim any late payment interest, fees, compensation, or any other form of additional compensation from the Customer or the Platform.<br><br>" +
                    "<strong>29- </strong>In the event that the Client has unemployment insurance in which the Client names the Platform as a beneficiary, it is invalid and the Platform shall not make any payment. <br><br>" +
                    "<strong>30- </strong>The bank transfer fee for the PAYMENT GUARANTEE is deducted from the payment.<br><br>" +
                    "<strong>31- </strong>The Platform has the right to request all kinds of documents from the user about the sale, lease, use of the vehicle. In the event that the use of the PAYMENT ASSURANCE right to cheat or deceive is legally detected, each user agrees to pay 10 times the transaction fee as a penalty.   <br><br>" +
                    "</div>   </div> </div> </body> </html>'"
            }} />)
    }
    return (
		<Layout>
			<Helmet>
                <title>Owntic.com pays the rental If you can not </title>
                <meta name="description" content="No repayment is requested from the customer for the rental payment guarantee provided on behalf of the customer." />
			</Helmet>
            {ShowHtml()}
        </Layout>
    )
}

export default PaymentAssurance
import React from "react"
import { Layout } from "../../../components/Layout";

const BonusProgram = () => {
    return(
        <Layout>
            <div className='wrapper' style={{ height: '60vh', marginTop: '62px', textAlign: "center", fontSize: '25px', paddingTop: '50px' }}>
                <strong><h1 style={{ color: '#007bff' }}>Bonus Program Page</h1></strong>
                <p>Soon..</p>
            </div>
        </Layout>
    )
}

export default BonusProgram
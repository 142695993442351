import React from "react";
import "./Footer.scss";

// import Logo from "./Content/Images/logo.svg";
import Logo from "./Content/Images/new/OWNTICLOGO.png";
import { withTranslation } from "react-i18next";

//const TEST_SITE_KEY = "6LcQUvwnAAAAAJvrxuJOVJpWl3h_nLdHZxe5BCMp";  //444car.com
const TEST_SITE_KEY = "6LfCiQspAAAAAMU88iYs0a6Y4qKiVIYpb59xBSA9";

const DELAY = 1500;
const isMobile = window.innerWidth <= 800;

class Footer extends React.Component {
  static displayName = Footer.name;

  constructor(props) {
    super(props);
    console.log(props);

    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <footer className="footer-container" style={this.props.style}>
        <div class="container">
          <div className="row">
            <div
              className="col-lg-3 col-md-6 footer_left"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href ="/" className="footer-img">
              <img style={{ width: "100%"}} src={Logo} />
              </a>
              <label
                style={{
                  fontStyle: "italic",
                  marginTop: "0px",
                  marginBottom: "15px",
                }}
                className={"label"}
              >
                <div className="copyright text-center">
                  <span
                    id="hebele"
                    className="d-block"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Tactical ownership
                    <br />
                  </span>
                  <span
                    id="hebele"
                    className="d-block"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    © 2024 Owntic All rights reserved.
                  </span>
                </div>
              </label>
            </div>
            <div className="col-lg-6 col-md-6 footer_nav">
              <ul>
                <li>
                  <a href="/carcompanies">{t("footerCarCompanies")}</a>
                </li>
                <li>
                  <a href="/ownticbroker">{t("footerOwnticBroker")}</a>
                </li>
                <li>
                  <a href="/Enterprise">{t("footerEnterprise")}</a>
                </li>
                <li>
                  <a href="/faq">{t("footerCarCompanyQuestions")}</a>
                </li>
                <li>
                  <a href="/pricing">{t("footerPricing")}</a>{" "}
                </li>
                <li>
                  <a href="/car-free-data">{t("Car Free Data")}</a>
                </li>
              </ul>

              <ul>
                {/*<li>*/}
                {/*    <a href="#/faq?type=1">{t("footerCustomerQuestions")}</a>*/}
                {/*</li>*/}

                <li>
                  <a href="/UserAgreement">{t("footerUserAgreement")}</a>
                </li>
                <li>
                  <a href="/CustomerProtectionAgreement">
                    {t("footerCustomerProtectionAgreement")}
                  </a>
                </li>
                <li>
                  <a href="/PaymentAssurance">{t("footerPaymentAssurance")}</a>{" "}
                </li>
                <li>
                  <a href="/PrivacyPolicy">{t("footerPrivacyPolicy")}</a>{" "}
                </li>
                <li>
                  <a href="/PrivacyNotice">{t("footerPrivacyNotice")}</a>{" "}
                </li>
              </ul>

              <ul>
                <li>
                  <a href="/contact">{t("footerContact")}</a>
                </li>
                <li>
                  <a href="/Owntic_Card">{t("footerCardOwntic")}</a>
                </li>
                <li>
                  <a href="https://blog.owntic.com" target="_blank">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 footer_right">
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Owntic Vehicle Solutions, Inc.<br />
                            8 The Green STE D Dover, <br />
                            DE 19901
                <br />
              </p>
              <div class="footer_social text-center">
                <div style={{ width: "250px" }} class="">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/owntic_com"
                    class="yt mx-2"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=61557920664256"
                    class="fb mx-2"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@OwnticUSA"
                    class="yt mx-2"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                  {/*
                  <a
                    target="_blank"
                    href="https://www.reddit.com/user/owntic"
                    class="yt mx-2"
                  >
                    <i class="fab fa-reddit"></i>
                  </a>
                  */}
                  <div class="footer_social text-center" className="link">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/owntic"
                      class="icons"
                    >
                      <i class="fab fa-linkedin"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/owntic"
                      class="tw mx-2"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.pinterest.com/owntic/"
                      class="tw mx-2"
                    >
                      <i class="fab fa-pinterest"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default withTranslation()(Footer);

import React from "react"
import { Layout } from "../../../components/Layout";
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function ShowHtml() {
	
	return (
		<div dangerouslySetInnerHTML={{
			__html: "<html>" +
				"<head>" +
				"<meta charset='utf-8'>" +
				"<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
				"<title>Projects</title>" +
				"<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
				"<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
				"rel='stylesheet'>" +
				"<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
				"<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
				"<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
				"<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
				".template_content{border - radius:4px;margin:20px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
				".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
				"</head><body> <div class='container'>" +
				"<div class='template'>" +
				"<div class='template_content'>" +
				"<br><p><strong><u><h3>OWNTIC TERMS OF USE</h3></u></strong>" +
				"<br><strong>1. INITIAL PROVISIONS</strong><br>" +
				"The legal terms and conditions set forth below (the 'Agreement') set forth the legal framework for the use of owntic.com website (the 'Platform') and the use of the products and services available on the Platform. <br>" +

				"<br><strong>2. SUBJECT MATTER AND FORM OF THE AGREEMENT</strong><br>" +
				"The Agreement shall be made available to the parties electronically and shall be valid upon acceptance electronically.  <br>" +

				"<br><strong>3. DEFINITIONS</strong><br>" +
				"<strong>3.1. Parties to the Contract</strong><br>" +
				"<strong>3.1.1. Platform: </strong>refers to the online marketplace offered to the service of Users through the website www.owntic.com and its sub-pages.<br>" +
				"<strong>3.1.2. Customer: </strong>Refers to natural and legal persons who are registered as a member of the Platform or who are registered in the name of someone else by the authorized owner to sell the vehicle and continue to use it by renting it, to sell and rent the other vehicle, to sell only, to rent only.<br>" +
				"<strong>3.1.3. Business: </strong>Refers to real or legal persons who provide or mediate vehicle sales and/or rental services to Platform member Customers.<br>" +
				"<strong>3.1.4. User: </strong>Refers to the parties (Customer and Business) who benefit from the Platform by accepting the User Agreement.<br>" +
				"<strong>3.1.5. Platform Operator: </strong>Owntic Vehicle Solutions Inc. and its affiliates, which own and operate the owntic.com Platform.<br>" +
				"<strong>3.2. Other</strong><br>" +
				"<strong>3.2.1. Vehicle: </strong>Means any road vehicle with a license plate in accordance with the laws of the United States of America and specialized construction equipment subject to sale and/or rental activities through the Platform.<br>" +
				"<strong>3.2.2. Representative: </strong>The representative who conducts Platform transactions on behalf of the User.<br>" +
				"<strong>3.2.3. Business Representative: </strong>Refers to the persons who carry out the transactions of the Enterprises registered on the Platform and who are members of the Platform. <br>" +
				"<strong>3.2.4. 'Sell and Continue to Use by Renting' Service: </strong>It is the service where the Customer's vehicle is sold to the Business and continued to be used by renting it to the Customer. <br>" +
				"<strong>3.2.5. 'RENT OPTION' - 'Sell-Rent Other Vehicle' Service: </strong>It is the sale of the Customer's vehicle to the Business or to a business partner of the Business and the leasing of another vehicle to the Customer.<br>" +
				"<strong>3.2.6. Offer: </strong>Refers to the types of sale-rental-service types and pricing made by the Business for the sale or lease of the vehicles registered on the Platform.<br>" +
				"<strong>3.2.7. Off-Platform Contract: </strong>The contract between the Customer and the Business for the price and conditions of the sale and/or lease of the vehicles registered on the Platform. <br>" +
				"<strong>3.2.8. Lease Payment Assurance: </strong>It refers to the payment by the Platform in case the Customer fails to pay the monthly lease price of the vehicle. The conditions for this are separately regulated in this agreement under the heading 'Payment Assurance'.<br>" +
				"<strong>3.2.9. Road Fuel Assistance: </strong>This is a type of service where the Platform lends the user the fuel price predetermined by the Platform for transactions executed through the Platform. The relevant terms and conditions are set out separately in this Agreement under the heading 'CARD OWNTIC'. The Platform may provide other additional services to the User here.<br>" +
				"<strong>3.2.10. Business Day: </strong>Business days, excluding days when the Platform does not officially operate in the United States of America.<br>" +

				"<br><strong>4. MEMBERSHIP</strong><br>" +
				"<strong>4.1. </strong>Membership transactions are completed when the users accept the Platform terms of use by filling out the membership form on the Platform.<br>" +

				"<br><strong>5. SERVICES</strong><br>" +
				"<strong>5.1. </strong>The Platform may intermediate Clients and Businesses for different supporting benefits, including Financial Institution services.<br>" +

				"<br><strong>6. PLATFORM FUNCTIONING</strong><br>" +
				"<strong>6.1. </strong>After becoming a member of the Platform, the customer fills in the vehicle information, sales price and rental conditions in the form on the Platform.<br>" +
				"<strong>6.2. </strong>Vehicle license plate, vehicle owner information and net mileage information of the vehicle are not shared with the businesses.<br>" +
				"<strong>6.3. </strong>The advertisement remains open for three (3) business days for businesses to make an offer. Requests or offers are only shared with the relevant parties, not published openly; only members can see the advertisements, only the person who placed the advertisement can see the offers.<br>" +
				"<strong>6.4. </strong>If an agreement is made between the Client and the Business, the amount specified in the Platform tariff must be paid within two (2) business days following the agreement. If no agreement is made between the Client and the Business, no payment is made to the Platform.<br>" +
				"<strong>6.5. </strong>The Platform may request result information from the user by sending a message to the e-mail address provided during the membership process within the period after the offers are sent. In case the User does not make a payment, the Platform will be notified by sending a message from the e-mail address. If the Business or the Customer does not respond to this information request within ten (10) days as of the day the message is received, the transaction will be deemed to have taken place. Notifications to be made using e-mail and digital channels within the scope of this article shall be deemed as duly notification.<br>" +
				"<strong>6.5.1. </strong>If the Business and the Customer do not pay the Platform transaction fee and the Platform determines that the transaction has occurred, the Business and the Customer must pay (2) times the penalty, delay fee, interest and fee at the Platform standard price. Legal follow-up and collection expenses are excluded. In this case, they cannot benefit from legal protection, rent assurance and other Platform services.<br>" +
				"<strong>6.5.2. </strong>In the event that the Platform is used only for vehicle sales, only for vehicle leasing, only for vehicle exchange, the Platform fee is applied exactly as it is.<br>" +
				"<strong>6.6. </strong>THE VEHICLE LEASING AGREEMENT BETWEEN THE CUSTOMER AND THE BUSINESS CANNOT CONTAIN CLAUSES THAT FORCE THE VEHICLE TO BE SOLD TO ITS PREVIOUS OWNER AND CANNOT BE SOLD BACK TO ITS PREVIOUS OWNER AT THE END OF THE LEASING PERIOD. EVEN IF THIS CLAUSE IS INCLUDED IN THE CONTRACT BETWEEN THE VEHICLE OWNER AND THE VEHICLE COMPANY, THE PARTIES AGREE THAT IT IS INVALID. <br>" +
				"<strong>6.7. </strong>THE APPLICATION SUBJECT TO ARTICLE 6.6. IN THE LEASE AGREEMENT TO BE MADE DIRECTLY BETWEEN THE FINANCIAL LEASING COMPANY AND THE VEHICLE OWNER COMMERCIAL ENTERPRISES IS SUBJECT TO THE LEGAL PROVISIONS OF THE UNITED STATES OF AMERICA REGARDING FINANCIAL LEASING.<br>" +
				"<strong>6.8. </strong>The Platform shall have no liability in the event that the vehicles sold or rented through the Platform are defective, are not in the specifications specified on the website and / or in the contract, a higher price is requested at the end of the rental process than the initial offer, the vehicle is not original, the delivery of the vehicle / vehicles is delayed, the vehicle is not delivered, the vehicle is not delivered, the contract is withdrawn from the contract and in all similar cases, including but not limited to these cases.  <br>" +
				"<strong>6.9. </strong>The Platform may act as an intermediary for the customer to inform the Business for vehicle delivery, return, accident, damage. The Platform may direct vehicle pictures, status photos and descriptions to the Business via the Platform. These information contents and photos (accident, damage, delivery, return vehicle status) are not stored by the Platform. The User cannot hold the Platform responsible for the failure of the Platform to deliver, delay or archive them to the Business.<br>" +
				"<strong>6.10. </strong>The Platform reserves the right to inquire from the official authorities regarding the sale, rental and transfer (change of vehicle owner) of the vehicle subject to sales and rental transactions. The User agrees to instruct the relevant authority for the Platform to carry out the necessary research regarding the change of ownership of the vehicle, to authorize the Platform Operation, and to give a limited power of attorney to query this information when necessary. Otherwise, he/she agrees that he/she will be held responsible for any fees earned by the Platform within the scope of the transaction. When necessary, the User shall provide a valid authorization document or power of attorney that includes the vehicle identification number(VIN) or license plates in the advertisements and will allow the User to obtain information from the official authorities. In the event that the User fails to comply with these, the User must pay the platform standard price together with penalties, late fees, interest and fees.<br>" +
				"<strong>6.11. </strong>All works (text, pictures, images, photos, videos, music, etc.) contained in the content of the Platform and the related website belong to the Platform Operator and are protected by property laws. The design and content of the website to which the Platform is linked is considered as a 'work' and the ownership, financial, exploitation, use and transfer rights of this work belong to the Platform Operator. In case of violation of these rights, all legal rights of the Platform Operator are reserved.<br>" +

				"<br><strong>7. RIGHTS AND OBLIGATIONS UNDER THE CONTRACT</strong><br>" +
				"<strong>7.1. </strong>The User is responsible for the statements made by the User through the Platform. The User accepts that the Platform does not make any declaration, guarantee or commitment regarding the accuracy, quality and security of the advertisement and offer contents. <br>" +
				"<strong>7.2. </strong>The Business cannot force the Customer to repurchase the vehicle at the end of the term, cannot specify conditions in the contract between them for this purpose, and the customer cannot demand the purchase. Any form of resale is prohibited and may not even be offered.<br>" +
				"<strong>7.3. </strong>The Platform may reduce or increase the number of offers transmitted to the Client. The 'best offer' application offered by the Platform is filtered and evaluated by the Platform, taking into account parameters such as the highest vehicle sales offer, the lowest rental price, the highest usage (MIL) amount. The User accepts that the choice made by the Platform on his/her behalf is the most appropriate choice in the offers offered to him/her by the Platform.<br>" +
				"<strong>7.4. </strong>In order not to pay the service fee received through the Platform, it is a fraudulent transaction to carry out the transaction through the User's affiliated business, group company, subsidiaries, affiliates, other companies or third parties with which its partners are partners. In this case, it is assumed that the contract has been realized. The service fee is 2 times the user's penalty, late fee, interest, legal costs are calculated separately.<br>" +
				"<strong>7.5. </strong>The Platform reserves the right to delete the registrations of real persons who intentionally use misleading contact information (mobile phone, email) or someone else's identity information during registration on the platform, and those who open registrations on behalf of legal entities they do not represent, those who intentionally declare misleading or fake vehicle license plates, someone else's vehicle information and license plates during the customer identification process, and to make the necessary legal notifications if these misleading records contain criminal elements.<br>" +
				"<strong>7.6. </strong>The Platform reserves the right to inquire through the Courts and official authorities regarding the fee entitled under this Agreement for the services received for the registered Vehicle. The User accepts that the transaction will be deemed to have taken place if the official authorities and the relevant registration / tracking organizations request information and confirmation on this subject, but refuse or fail to fulfill this request, and is obliged to pay the service fee of the Platform and accepts that this situation may be used as evidence against him in legal proceedings.<br>" +
				"<strong>7.7. </strong>Statements regarding the condition and value of the Vehicle for which the Customer requests registration on the Platform are not controlled and guaranteed by the Platform.<br>" +

				"<br><strong>8. FEES and PAYMENTS</strong><br>" +
				"<strong>8.1. </strong>Membership to the platform is free of charge. Bidding for vehicles on the platform is free of charge.  Bidding for these vehicles is free of charge. Fees are paid on the Platform only if the parties agree on a transaction. The fee for the brokerage services offered by the Platform is paid separately by the Business and the Client. Platform service fee, vehicle fee and payment term can be found on the 'Pricing' page.   The Platform reserves the right to make unilateral changes to the fee schedule.<br>" +
				"<strong>8.2. </strong>The Platform is entitled to a fee upon the agreement of the parties and the realization of the transaction.<br>" +
				"<strong>8.3. </strong>The Client and the Business have the right to benefit from discounted rates within 10 days from the submission of offers.<br>" +
				"<strong>8.4. </strong>The Customer may send vehicle pick-up, damage, accident and vehicle return reports and vehicle photos to the Business via the Platform. In these transactions, time stamp, location, Customer declaration can be used. This information can be sent as an e-mail to the Platform membership address of the Business.<br>" +
				"<strong>8.5. </strong>A copy of this agreement is sent to the user as a link. <br>" +
				"<strong>8.6. </strong>The validity period of the Business offer is at least 6 business days. The business can extend this period. The deadlines can be dynamically changed by the Platform during the term. The change will be displayed before the Business makes an offer to the Client.  <br>" +
				"<strong>8.7. </strong>The Business will deliver the vehicle with at least 50 USD free fuel and will not charge a fee for the return of the vehicle. The amount may be dynamically changed by the Platform during the term. The change will be displayed prior to the Business making an offer to the Client.<br>" +
				"<strong>8.8. </strong>The Business shall provide at least 100 miles of free overage in the total rental period and shall not charge a fee for the use of this amount. The amount may be dynamically changed by the Platform during the period. The change will be displayed prior to the Business making an offer to the Customer.<br>" +
				"<strong>8.9. </strong>The Operator will not charge the Customer for damages up to a limit of USD 100 for vehicle return. The amount may be dynamically changed by the Platform during the term. The change will be displayed before the Business makes an offer to the Client.<br>" +
				"<strong>8.10. </strong>Vehicle insurance, vehicle liability insurance, vehicle tax, summer and winter tires, periodic vehicle maintenance and control are included in the Business offer. No additional payment can be requested from the Customer for these expenses.<br>" +
				"<strong>8.11. </strong>During the Business offer, the Customer may provide the 'Optional/Extra Services' listed in the Platform list for a fee or free of charge. <br>" +
				"<strong>8.12. </strong>The Platform is not responsible for calculations, comparisons, vehicle evaluation reference information and pricing made on the Platform. The Platform is not a party to the contract to be made between the Business and the Customer and cannot be held responsible for its content. <br>" +
				"<strong>8.13. </strong>In order to use the Platform services, the Business and the Client must have paid the platform fee.<br>" +
				"<strong>8.14. </strong>If the Platform fee is not paid within 30 days, 5% monthly default interest is charged. Failure to notify the transaction even though the transaction has taken place or failure to respond to the request for information from the e-mail address used by the Platform in the membership or providing information that the transaction has not taken place is subject to a penalty. In this case, it is accepted that a 2x penalty will be paid in addition to the standard fee. Legal follow-up costs and interest related to this shall be paid by the Customer or the Business.<br>" +
				"<strong>8.15. </strong>The payment of Vehicle sale and rental fees between the Business and the customer to be made due to the services performed within the scope of the Platform shall be realized through bank transfer. It is forbidden for the Customer and the Business to make payments, collections and related reconciliations externally, and the Platform shall not be held liable for any damage, loss, criminal and financial liabilities arising from behaviors contrary to this Article.<br>" +
				"<strong>8.16. </strong>The Platform may receive a service fee from the user or supplier in case of expertise, insurance, arranging vehicle purchase/sale/lease agreements, providing financing to the Business or mediating in these matters.<br>" +
				"<strong>8.17. </strong>In cases where the Platform makes a payment to the user, the bank transfer fee is deducted from the payment made.<br>" +

				"<br><strong>9. DURATION AND VALIDITY OF THE CONTRACT</strong><br>" +
				"<strong>9.1. </strong>This Agreement is completed with user acceptance. The Agreement is indefinite.<br>" +

				"<br><strong>10. PRIVACY and DATA SECURITY</strong><br>" +
				"<strong>10.1. </strong>By accepting this Agreement, Users agree that the Platform may share information with the User via telephone voice communication, whatsapp, SMS, e-mail, fax, mail or any other means of communication in order to promote the services provided and manage the processes.<br>" +
				"<strong>10.2. </strong>The Platform Operator takes the necessary technical, operational and legal measures to protect the data shared through the Platform and the communication realized from the access of third parties. More detailed information on data security and data processing can be accessed through the PRIVACY POLICY on the Platform. The User's acceptance of this Agreement means that he/she has read and understood the PRIVACY POLICY on the Platform and agrees to use the Platform in accordance with these rules.<br>" +

				"<br><strong>11. COMMUNICATION AND NOTIFICATION</strong><br>" +
				"<strong>11.1. </strong>The Platform may use SMS, e-mail or different channels verification and similar methods to determine the identity of the real or legal person user during registration, and may request the submission of originals or copies of personal / commercial information and documents.<br>" +
				"<strong>11.2. </strong>The User agrees that the telephone conversations and correspondence between the Platform and the User through various channels may be kept in order to be submitted to legal authorities in case of disputes and will be made accessible upon request by the authorities.<br>" +
				"<strong>11.3. </strong>The Platform may change this Agreement and/or its updated articles at any time by notifying the User via the User's e-mail address registered on the Platform or by publishing it on the Platform website accessible via the internet address www.owntic.com. <br>" +

				"<br><strong>12. DISPUTE</strong><br>" +
				"<strong>12.1. </strong>In the event of a legal dispute between the parties arising from the use of the Platform, the Courts where the Platform Company headquarters is located are authorized. The User agrees that the Platform has the right to unilaterally change the authorized court. The Platform reserves the right to unilaterally choose a mediator in case of dispute / commercial dispute with this agreement. (13.11. ??????)<br>" +

				"<br><strong>13. GENERAL PROVISIONS</strong><br>" +
				"<strong>13.1 ACCEPTANCE OF TERMS</strong><br>" +
				"By accessing the Site, you acknowledge that you have read, understand and agree to be bound by all of these Terms of Use. Use. IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF USE, YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND MUST CEASE USE IMMEDIATELY.<br>" +
				"We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you to any changes by updating the 'Last updated' date of these Terms of Use and you waive the right to receive specific notice of each such change. If you continue to use the Site after the date such revised Terms of Use are posted, you will be subject to the changes in the revised Terms of Use and will be deemed to have notice of and accept such changes.<br>" +
				"The information provided on the Site is not intended for distribution or use to any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or would subject us to any registration requirements in such jurisdiction or country. . Accordingly, persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.<br>" +
				"You may not use the Site in violation of the Gramm-Leach-Bliley Act (GLBA).<br>" +
				"The Site is intended for users who are at least 18 years of age. Persons under 18 years of age are not permitted to use or register on the Site.<br>" +
				"<strong>13.2. INTELLECTUAL PROPERTY RIGHTS</strong><br>" +
				"Unless otherwise indicated, the Site is our property and all source code, databases, functions, software, website designs, audio, video, text, photographs and graphics (collectively, the 'Content') and trademarks, service marks and logos ('Marks') contained therein are owned or controlled by or licensed to us and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. Content and Marks are provided 'AS IS' on the Site solely for your information and personal use. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, assembled, republished, republished, uploaded, posted, publicly displayed, encoded,<br>" +
				"Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly obtained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in the Site, Content and Marks.<br>" +
				"<strong>13.3. USER STATEMENTS</strong><br>" +
				"By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have legal capacity and agree to abide by these Terms of Use; (4) you are a minor in your jurisdiction of residence; (5) you will not access the Site by automated or non-human means, whether by a bot, script or otherwise; (6) you represent that you will not use the Site for any illegal or unauthorized purpose; and (7) your use of the Site will not violate any applicable law or regulation.<br>" +
				"If you provide any information that is untrue, inaccurate, out of date or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any part thereof).<br>" +
				"<strong>13.4. USER REGISTRATION</strong><br>" +
				"You may be required to register on the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, revoke or modify your chosen username if we determine, in our sole discretion, that such username is inappropriate, obscene or otherwise objectionable.<br>" +
				"<strong>13.5. PROHIBITED ACTIVITIES</strong><br>" +
				"You may not access or use the Site for any purpose other than for the purpose for which we have made the Site available. The Site may not be used in connection with any business venture other than those specifically approved or authorized by us.<br>" +
				"As a user of the Site, you agree not to:<br>" +
				"<li> Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database or directory without our written permission.<br>" +
				"<li> Trick, defraud or mislead us and other users in any attempt to learn sensitive account information, particularly user passwords.<br>" +
				"<li> circumvent, disable or otherwise interfere with the security-related features of the Site, including features that prevent or restrict the use or copying of any Content or limit the use of the Site and/or the Content contained therein.<br>" +
				"<li> In our opinion, defame, disparage or otherwise damage us and/or the Site.<br>" +
				"<li> Use any information obtained from the Site to harass, abuse or harm another person.<br>" +
				"<li> Use our support services inappropriately or submit false reports of abuse or misuse.<br>" +
				"<li> Use the Site in a manner contrary to applicable laws or regulations.<br>" +
				"<li> Unauthorized framing of the Site or linking to the Site.<br>" +
				"<li> Upload or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material that interferes with any party's uninterrupted use and enjoyment of the Site, including excessive capitalization and spam (the posting of repetitive text) that may alter, disrupt, impair, impair, modify or interfere with the use, features, functions, operation or maintenance of the Site.<br>" +
				"<li> Do not engage in any automated use of the system, such as using scripts to post comments or messages or using any data mining, robots or similar data gathering and extraction tools.<br>" +
				"<li> Remove any copyright or other proprietary rights notices from any Content.<br>" +
				"<li> Attempt to impersonate another user or person or use another user's user name.<br>" +
				"<li> Upload or transmit (or attempt to upload or transmit) any material that acts as a passive or active information collection or transmission mechanism, including but not limited to clear graphic interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcm's').<br>" +
				"<li> Interfere with, disrupt, or create an undue burden on the Site or networks or services connected to the Site.<br>" +
				"<li> Harass, harass, intimidate or threaten our employees or agents who provide any part of the Site to you.<br>" +
				"<li> Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site or any part of the Site.<br>" +
				"<li> Copy or adapt the Site's software, including but not limited to Flash, PHP, HTML, JavaScript or other code.<br>" +
				"<li> Except as permitted by applicable law, you may not decrypt, decompile, disassemble or reverse engineer any software that makes up the Site or any portion of the Site in any way.<br>" +
				"<li> Use, launch, develop or distribute any automated system, including, without limitation, any spider, robot, cheat utility, scraper or offline reader that accesses the Site, or use or launch any unauthorized script or other software, except as may result from the use of a standard search engine or Internet browser.<br>" +
				"<li> Make any unauthorized use of the Site, including collecting users' usernames and/or mailing addresses by electronic or other means for the purpose of sending unsolicited e-mail or creating user accounts by automated means or under false pretenses.<br>" +
				"<li> Use the Site as part of any effort to compete with us or use the Site and/or Content for any revenue-generating endeavor or commercial enterprise.<br>" +
				"<li> Sell or otherwise transfer your profile.<br>" +
				"<strong>13.6. CONTRIBUTION LICENSE</strong><br>" +
				"You and the Site agree that we may access, store, process and use any information and personal data you provide in accordance with the terms of the Privacy Policy and your preferences (including settings).<br>" +
				"By submitting suggestions or other feedback regarding the Site, you agree that we may use and share such feedback for any purpose without any compensation to you.<br>" +
				"We do not claim any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not responsible for any representations or statements regarding your Contributions provided by you in any area of the Site. You are solely responsible for Your Contributions to the Site and you expressly agree to release us from any and all liability with respect to Your Contributions and to refrain from taking any legal action against us.<br>" +
				"<strong>13.7. SUBMISSIONS</strong><br>" +
				"You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information regarding the Site ('Submissions') provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall have the right to use and distribute such Submissions without restriction for any lawful purpose, commercial or otherwise, without notice or compensation to you. You waive all moral rights in such Submissions and warrant that such Submissions are original by you or that you have the right to submit such Submissions. You agree that you shall have no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.<br>" +
				"<strong>13.8. SITE ADMINISTRATION</strong><br>" +
				"We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against any person who, in our sole discretion, violates the law or these Terms of Use, including, but not limited to, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, reject, restrict, limit the availability of, or (to the extent technologically feasible) disable any or any portion of your Contributions; (4) in our sole discretion and without limitation, notice or liability of any kind, remove or otherwise disable from the Site all files and content that are excessive in size or in any way burdensome to our systems; and (5) administer the Site in a manner that protects our rights and property and facilitates the proper functioning of the Site.<br>" +
				"<strong>13.9. CONFIDENTIALITY POLICY</strong><br>" +
				"We care about data privacy and security. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please note that the Site is hosted in the United States. If you are accessing the Site from another part of the world, you are transferring your data by continuing to use the Site when there are laws or other requirements regarding the collection, use or disclosure of personal data that differ from applicable laws in the United States. You are sending to the United States and you expressly consent to the transfer of your data to the United States and its processing there.<br>" +
				"<strong>13.10. DURATION AND TERMINATION</strong><br>" +
				"These Terms of Use will remain in full force and effect for the duration of your use of the Site. WITHOUT LIMITING ANY OTHER PROVISIONS OF THESE TERMS OF USE, WE RESERVE THE RIGHT, IN OUR SOLE DISCRETION AND WITHOUT PRIORITY OR LIABILITY, TO DENY ACCESS TO AND USE OF THE SITE TO ANY PERSON FOR ANY OR NO REASON WHATSOEVER (INCLUDING BLOCKING CERTAIN IP ADDRESSES), INCLUDING, BUT NOT LIMITED TO, ANY BREACH OF ANY REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THESE TERMS OF USE OR ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION ON THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION YOU HAVE POSTED AT ANY TIME, WITHOUT NOTICE, IN OUR SOLE DISCRETION.<br>" +
				"We cannot guarantee that the Site will always be available. We may encounter hardware, software or other problems with the Site or need to perform maintenance, which may cause interruptions, delays or errors. We reserve the right to change, revise, update, suspend, discontinue or otherwise modify the Site at any time or for any reason without notice to you. You agree that we shall have no liability whatsoever for any loss, damage or inconvenience caused by your inability to access or use the Site during any interruption or downtime of the Site. Nothing in these Terms of Use shall be construed to obligate us to maintain and support the Site or to provide any corrections, updates or releases in connection therewith.<br>" +
				"<strong>13.11. APPLICABLE LAW</strong><br>" +
				"These Terms of Use and your use of the Site shall be governed by and construed in accordance with the laws of the State of Texas applicable to agreements made and to be performed entirely within the State of Texas, without regard to principles of conflicts of law.<br>" +
				"<strong>13.12. DISPUTE RESOLUTION</strong><br>" +

				"<strong>Informal Negotiations</strong><br>" +
				"If you or we (individually, a 'Party' and collectively, the 'Parties') have any dispute regarding these Terms of Use, the Parties agree to first attempt to informally negotiate any Dispute (except for Disputes expressly set forth below) for at least thirty (30) days before initiating arbitration. Such informal negotiations shall commence upon written notice by one party to the other party.<br>" +

				"<strong>Binding Arbitration</strong><br>" +
				"Arbitration Clause and Jury Trial Provision Waiver. Arbitration is a way of using a neutral arbitrator to resolve disputes. The term 'disputes' has the broadest possible meaning. The arbitrator can grant injunctions like a court. Unlike a court, there is no jury and no class action. The arbitrator's decision is binding and subject to limited appeal under applicable law. Courts rarely overturn arbitration awards. You do not have to arbitrate disputes. You can sue individually in a court of limited jurisdiction that does not allow class action claims, such as a small claims court ('Small Claims Court'), or you can choose to opt out of this provision. By using the Site, you agree that arbitration will resolve disputes with us that cannot be resolved in Small Claims Court. All parties may seek resolution in the Small Claims Court for disputes within its jurisdiction. Any challenge to the decision of the Small Claims Court will be resolved through binding arbitration. YOU WAIVE YOUR RIGHT TO JURY TRIAL to resolve disputes. The Federal Arbitration Act governs this arbitration provision. If either party has a dispute, it must notify the other party in writing. You can choose the organization that administers the arbitration from one of two options: The American Arbitration Association www.adr.org or JAMS www.jamsadr.org. We will pay the costs of the arbitration. Unless the arbitrator decides otherwise, you will pay your lawyer's fees, if any. The arbitrator cannot conduct class arbitration. The arbitrator must follow applicable law. If a hearing is required, it will be held in a reasonable place, such as the county where you live. You can opt out of arbitration within 60 calendar days of using the owntic website by writing to us and telling us you want to opt out.<br>" +

				"<strong>13.13. DISCLAIMER OF LIABILITY</strong><br>" +
				"THE SITE IS PROVIDED AS IS AND AS AVAILABLE. YOU AGREE THAT YOUR USE OF THE SITE SERVICES IS ENTIRELY AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT IN CONNECTION WITH THE SITE AND YOUR USE OF THE SITE. WE MAKE NO WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY OR COMPLETENESS OF THE CONTENT OF THE SITE OR ANY WEBSITE LINKED TO THIS SITE, AND WE DISCLAIM ALL LIABILITY FOR (1) ERRORS IN THE CONTENT AND MATERIALS, ERRORS OR INACCURACIES, (2) WE WILL NOT ACCEPT ANY LIABILITY OR RESPONSIBILITY FOR PERSONAL ERRORS, MISTAKES OR INACCURACIES. INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR STOPPAGE OF TRANSMISSION TO OR FROM THE SITE, (5) ANY ERROR, VIRUSES, TROJANS, HORSES OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED OR OTHERWISE MADE AVAILABLE THROUGH THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY PRODUCTS OR SERVICES ADVERTISED OR OFFERED BY ANY THIRD PARTY ON THE SITE, ANY HYPERLINKED WEBSITE OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISEMENT. AND WE WILL NOT BE A PARTY TO ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY PRODUCT OR SERVICE PROVIDER, NOR WILL WE BE LIABLE FOR ANY MONITORING IN ANY WAY. IN THE EVENT A PRODUCT OR SERVICE IS PURCHASED THROUGH ANY INTERMEDIARY IN ANY MEDIUM, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHEN APPROPRIATE.<br>" +
				"<strong>13.14. LIMITATIONS OF LIABILITY</strong><br>" +
				"IN NO EVENT WILL WE OR OUR DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS RELY AGAINST YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST REVENUES, LOST DATA OR OTHER DAMAGES. EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES ARISING OUT OF YOUR USE OF THE SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION SHALL AT ALL TIMES BE LIMITED TO: THE AMOUNT PAID BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO THE OCCURRENCE OF ANY LITIGATION, IF ANY. CERTAIN US STATE AND INTERNATIONAL LAWS DO NOT ALLOW THE LIMITATION OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, IF SUCH LAWS APPLY TO YOU,<br>" +
				"<strong>13.15. COMPENSATION</strong><br>" +
				"You agree to defend, indemnify and hold us, including our subsidiaries, affiliates and all of our respective officers, agents, partners and employees, harmless from and against any and all losses, damages, liabilities, claims or demands, including reasonable attorneys. ' (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of any rights of a third party, including but not limited to intellectual property rights; or (5) any overtly harmful conduct directed at any other user of the Site with whom you connect through the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, in our defense of any such claim. We will use reasonable efforts to notify you upon our becoming aware of any such claim, suit or proceeding subject to this indemnification.<br>" +
				"<strong>13.16. USER DATA</strong><br>" +
				"We will retain certain data you transmit to the Site for the purpose of managing the performance of the Site, as well as data about your use of the Site. Although we regularly perform routine data backups, you are solely responsible for all data that you transmit or that relates to any activity you perform using the Site. You agree that we shall have no liability to you for the loss or corruption of such data and you waive any right of action against us arising out of the loss or corruption of such data.<br>" +
				"<strong>13.17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS AND SIGNATURES</strong><br>" +
				"Visiting the Site, sending us e-mails and completing online forms constitute electronic communications. We require all customers to execute an E-CONSENT AGREEMENT, Disclosures and Consent to Conduct Business Electronically with Records ('E-Consent'). By CONFIRMING THIS AGREEMENT, customers agree to receive electronic communications and conduct business electronically.<br>" +
				"<strong>13.18. CALIFORNIA USERS AND THOSE WHO ARE</strong><br>" +
				"If any complaint about us is not satisfactorily resolved, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.<br>" +
				"<strong>13.19. MISCELLANEOUS</strong><br>" +
				"No joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us because you have drafted them. You waive any defenses you may have based on the electronic form of these Terms of Use and the failure of the parties to sign to enforce these Terms of Use. You agree that these Terms of Use will not be construed against us because you have drafted them. You waive any defense you may have based on the electronic form of these Terms of Use and the failure of the parties to sign to enforce these Terms of Use. You agree that these Terms of Use will not be construed against us because you have drafted them. You waive any defense you may have based on the electronic form of these Terms of Use and the failure of the parties to sign to enforce these Terms of Use.<br>" +

				"<br><strong>14. FINAL PROVISIONS</strong><br>" +
				"<strong>14.1. </strong>This Agreement has been prepared in English. Other language versions of the Agreement may be made available on the Platform to cater to different users. In the event of any difference or conflict between versions of the Agreement translated into other languages, the English version of the Agreement shall prevail.<br>" +
				"<strong>14.2. </strong>The invalidity of any clause of this Agreement in whole or in part before the legal authorities shall not affect the validity of the remainder of the content of the clause or the validity of the other clauses of the Agreement.<br>" +
				"<strong>14.3. </strong>Additional terms and conditions validly issued and referring to this agreement shall be deemed an integral part of this Agreement. The partial or total invalidity of any article of the additional terms referring to this agreement before the legal authorities shall not affect the validity of the rest of the article content, other articles and this membership agreement referred to.<br>" +
				"<strong>14.4. </strong>This agreement is issued in electronic contract format and becomes valid as soon as it is accepted.<br>" +
				"<strong>14.5. </strong>All kinds of taxpayer sole proprietorships, self-employed persons and companies may also receive offers from financial leasing companies on the platform.  The user's will to examine and approve that the financial leasing company offering the offer is authorized to make transactions belongs to him. <br>" +
				"<strong>14.6. </strong>Vehicle and financial leasing brokers-companies may bid on the platform. In this case, the will to examine and approve that the bidder is authorized belongs to the user.<br>" +

				"<br><strong>15. </strong>'<strong><a href='https://www.owntic.com/CustomerProtectionAgreement'><u>LEGAL PROTECTION</u></a></strong>' Agreement is an integral part of the user agreement.<br>" +

				"<br><strong>16. </strong>'<strong><a href='https://www.owntic.com/PaymentAssurance'><u>PAYMENT ASSURANCE</u></a></strong>' Agreement is an integral part of the user agreement.<br>" +

				"<br><strong>17. </strong>'<strong><a href='https://www.owntic.com/Owntic_Card'><u>CARD OWNTIC</u></a></strong>' Agreement is an integral part of the User Agreement.<br>" +
				"</p>" +

				"<div class='container'>" +
				"<div class='table_list'>" +
				"<div class='table- responsive'>" +
				"<table id='user-aggr' style='table-layout: fixed; width:100%; ' border='0' cellpadding='0' cellspacing='0' class='table-striped table table-bordered'>" +
				"<tbody>" +
				"<tr>" +
				"<td class='text-center'><strong><u>www.owntic.com</u><br></strong></td>" +
				"<td style='display:flex;'><strong style='display:block; margin:auto;'>USER</strong></td>" +
				"</tr>" +
				"<tr>" +
				"<td class='text-center'>	<strong>Owntic Vehicle Solutions Inc.</strong></td>" +
				"<td class='text-center'>	<strong>-</strong></td>" +
				"</tr>" +
				"</tbody>" +
				"</table>" +
				"<div>" +
				"</div>" +
				"</div>" +
				"<p>" +


				"</p>" +

				"</div>   </div> </div> </body> </html>"
		}} />)
}


export const UserAgreementComponent = () => {
	
	return (
		<>
			{ShowHtml()}
		</>
	)
}


const UserAgreement = () => {

    return (
		<Layout>
			<Helmet>
				<title>Sell & keep on using your car</title>
				<meta name="description" content="Owntic.com is a marketplace that brings car owners and car companies together, eliminating the need to use credit." />
			</Helmet>
            {ShowHtml()}
        </Layout>
    )
}

export default UserAgreement
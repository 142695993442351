import { RequestToken } from "./JWTService";
import axios from "axios";

class AuthService {
  _user = null;
  _isAuthenticated = false;

  isAuthenticated() {
    return this._isAuthenticated;
  }

  getUser() {
    return this._user;
  }

  getToken() {
    return localStorage.getItem("jwtToken");
  }

  setToken(token) {
    localStorage.setItem("jwtToken", token);
  }

  async signIn(email, password) {
    this.signOut();
    let response = await RequestToken({ email: email, password: password });

    if (response.data.statusCode.toString() != "500") {
      this.setToken(response.data.token);
    }
    return response;
  }
  signOut() {
    this.setToken(null);
  }

  async register(corporateType, companyNo, email, password, lang) {
    try {
      const response = await axios.post(
        "user/register",
        {
          specialCompany: corporateType,
          companyNo: companyNo,
          email: email,
          password: password,
          language: lang,
        },
        {}
      );
      return response;
    } catch (e) {
        return e.response.data;
    }
  }
  static get instance() {
    return authService;
  }
}
const authService = new AuthService();

export default authService;

import React, {Component} from 'react';
import {Container} from 'reactstrap';
import Footer from './Footer';
import NavMenuDashboard from './NavMenuDashboard';

export class Layout extends Component {

    render() {
       
        return (
            <div>
                <NavMenuDashboard/>
                <Container className="themed-container" fluid={true} style={{margin: "0px", padding: "0px"}}>
                    {this.props.children}
                </Container>
                <Footer/>
            </div>
        );
    }
}

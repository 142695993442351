import React, { Component } from "react";
import { getLocale, saveLocale } from "../Localization";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Spinner,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSignOutAlt, faUser, } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { post } from "../services/ApiServices";
import $ from "jquery";
// import Logo from "./Content/Images/logo.svg";
import Logo from "./Content/Images/new/OWNTICLOGO.png";
import ReCAPTCHA from "react-google-recaptcha";
import AuthService from "../services/AuthService";
import "./style.css";
import { CryptInfo } from "../util/utils";
import { withTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu'
import "./NavMenuDashboard.scss"

var CryptoJS = require("crypto-js");
//Prod
//const TEST_SITE_KEY = "6LcQUvwnAAAAAJvrxuJOVJpWl3h_nLdHZxe5BCMp";
const TEST_SITE_KEY = "6LfCiQspAAAAAMU88iYs0a6Y4qKiVIYpb59xBSA9";

//local
//const TEST_SITE_KEY = "6Levo1UaAAAAAHP8Xp1kKkVSeaB-q1-badarOLDX";

const DELAY = 1500;
const getUser2 = function () {
    try {
        var user = localStorage.getItem("user");
        if (!user) {
            return;
        }
        let secret = CryptInfo.SecretKey.toString();
        var bytes = CryptoJS.AES.decrypt(user, secret);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        user = JSON.parse(originalText);
        let userRole = user.role;
        let shortMail =
            user.email.length > 8 ? user.email.substring(0, 8) + "..." : user.email;
        return shortMail;
    } catch (e) {
        localStorage.clear()
        sessionStorage.clear()
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.includes("user")) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }

        setTimeout(function () {
            window.location.reload();
        });
    }
};

class NavMenuDashboard extends Component {
    static displayName = NavMenuDashboard.name;

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleLoginDropdown = this.toggleLoginDropdown.bind(this);
        this.toggleLogin = this.toggleLogin.bind(this);
        this.toggleLoginModal = this.toggleLoginModal.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.goToMyPage = this.goToMyPage.bind(this);
        this.goToHomePage = this.goToHomePage.bind(this);


        this.goToAddCar = this.goToAddCar.bind(this);
        this.goToNewArrivals = this.goToNewArrivals.bind(this);
        this.goToPayment = this.goToPayment.bind(this);
        this.goToPreferences = this.goToPreferences.bind(this);







        this.goToMyProfile = this.goToMyProfile.bind(this);
        this.SignOutOnClick = this.SignOutOnClick.bind(this);
        this.saveVehicleCompany = this.saveVehicleCompany.bind(this);
        this.saveVehicleOwner = this.saveVehicleOwner.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleOwnerModal = this.toggleOwnerModal.bind(this);

        this.state = {
            forgotPasswordMail: "",
            disableSubmitButton: true,
            collapsed: true,
            dropdownOpen: false,
            user: null,
            dropdownLoginOpen: false,
            loginDropdownOpen: false,
            registerModal: false,
            corporateType: 0,
            loginModal: false,
            registerOwnerModal: false,
            companyNo: "",
            email: "",
            shortMail: getUser2(),
            password: "",
            repassword: "",
            userContractText: false,
            secretContractText: false,
            kvkkContractText: false,
            lang: sessionStorage.getItem("lang") || "en",
            callback: "not fired",
            value: "[empty]",
            load: false,
            expired: "false",
            loginType: -1,
        };
        this._reCaptchaRef = React.createRef();
    }

    setRemoveSpinner() {
        $(".saveVehicleCompanyButton").css("display", "none");
        $(".loginUserButton").css("display", "none");
        $(".sendForgotPasswordButton").css("display", "none");
    }

    componentDidMount() {
        this.setRemoveSpinner();
        this.getUser();
    }

    clearModal() {
        this.setState({
            companyNo: "",
            email: "",
            password: "",
            repassword: "",
            userContractText: false,
            secretContractText: false,
            kvkkContractText: false,
            lang: getLocale(),
            callback: "not fired",
            value: "[empty]",
            load: false,
            expired: "false",
            showMenu: false,
        });
    }

    goToMyPage = () => {
        if (this.state.user != null && this.state.user.role == 1) {
            window.location.href = window.location.origin + "/dashboard/ads";
        }
        window.location.href = window.location.origin + "/dashboard/myads";
    };
    goToHomePage = () => {
        this.SignOutOnClick();
    };

    goToAddCar = () => {
        if (this.state.user != null && this.state.user.role == 1) {
            window.location.href = window.location.origin + "/dashboard/PublishAd";
        }
        window.location.href = window.location.origin + "/dashboard/PublishAd";
    };

    goToPayment = () => {
        if (this.state.user != null && this.state.user.role == 1) {
            window.location.href = window.location.origin + "/dashboard/payment";
        }
        window.location.href = window.location.origin + "/dashboard/payment";
    };

    goToNewArrivals = () => {
        if (this.state.user != null && this.state.user.role == 1) {

        }
        window.location.href = window.location.origin + "/dashboard/Ads";
    };
    goToPreferences = () => {
        if (this.state.user != null) {
            window.location.href = window.location.origin + "/sasdasdasd";
        }
        window.location.href = window.location.origin + "/dashboard/carpreferences";
    };




    goToMyProfile = () => {
        window.location.href = window.location.origin + "/dashboard/profile";
    };
    saveVehicleCompany = async () => {
        $(".saveVehicleCompanyButton").css("display", "block");
        if (
            this.state.email != null &&
            this.validateEmail(this.state.email) &&
            this.state.password == this.state.repassword &&
            this.state.userContractText
        ) {
            try {
                await AuthService.register(
                    this.state.corporateType,
                    this.state.companyNo,
                    this.state.email,
                    this.state.password
                );
                this.setState({ result: "Success" });
                Swal.fire(this.props.t("success"), this.props.t("PopIslemBasarili"), "success");
                this.closeDialogs();
                this.clearModal();
                this.toggleModal();
            } catch (e) {
                Swal.fire(this.props.t("error"), this.props.t("PopIslemBasarisiz"), "error");
                this.clearModal();
                this.toggleModal();
                this.closeDialogs();
                this.setRemoveSpinner();
                this.setState({ result: JSON.stringify(e.toString()) });
            }
        }
        this.setRemoveSpinner();
    };

    closeDialogs() {
        $(".closeRegister").click();
        $(".closeLogin").click();
    }

    saveVehicleOwner = async () => {
        if (
            this.state.email != null &&
            this.validateEmail(this.state.email) &&
            this.state.password == this.state.repassword &&
            this.state.userContractText
        ) {
            try {
                await AuthService.register(
                    "",
                    "",
                    this.state.email,
                    this.state.password
                );
                this.setState({ result: "Success" });
                Swal.fire(this.props.t("success"), this.props.t("PopIslemBasarili"), "success");
                this.closeDialogs();
                this.clearModal();
                this.toggleOwnerModal();
            } catch (e) {
                Swal.fire(this.props.t("error"), this.props.t("PopIslemBasarisiz"), "error");
                this.clearModal();
                this.toggleOwnerModal();
                this.closeDialogs();

                this.setState({ result: JSON.stringify(e.toString()) });
            }
        }
    };
    loginUser = async () => {
        $(".loginUserButton").css("display", "block");
        if (this.state.email != null && this.state.password) {
            try {
                await AuthService.signIn(this.state.email, this.state.password);
                this.setState({ result: "Success" });
                window.location.href = "/dashboard/faqs";
            } catch (e) {
                $(".closeLogin").click();
                Swal.fire(this.props.t("error"), this.props.t("PopIslemBasarisiz"), "error");
                this.clearModal();
                this.setRemoveSpinner();
                this.setState({ result: JSON.stringify(e.toString()) });
            }
        }
        this.setRemoveSpinner();
    };

    handleChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({ value });
        // if value is null recaptcha expired
        if (value === null) this.setState({ expired: "true" });
    };

    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    toggleOwnerModal() {
        this.setState({
            registerOwnerModal: !this.state.registerOwnerModal,
        });
    }

    toggleModal() {
        this.setState({
            registerModal: !this.state.registerModal,
        });
    }

    toggleLoginModal() {
        this.setState({
            loginModal: !this.state.loginModal,
        });
    }

    changeLanguage(e) {
        e.preventDefault();
        const newLang = this.state.lang == "en" ? "tr" : "en";
        this.setState({ lang: newLang });
        this.setLocale(newLang);
    }

    changeValue(e) {
        this.setState({ lang: e });
        this.setLocale(e);
    }

    toggleForgotPassword() {
        $(".unsucces").css("display", "none");
        $(".succes").css("display", "none");
        $(".forgot_password").slideToggle();
    }

    setLocale(locale) {
        sessionStorage.setItem("lang", locale)
        saveLocale(locale);
        setTimeout(function () {
            window.location.reload();
        }, 100);
    }

    SignOutOnClick = () => {
        Swal.fire({
            title: this.props.t("PopAreYouSure"),
            text: this.props.t("PopYouWantLogout"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: this.props.t("vazgec"),
            confirmButtonText: this.props.t("evet"),
        }).then((result) => {
            if (result.value) {
                sessionStorage.clear();
                localStorage.clear();
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    if (name.includes("user")) {
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                    }
                }
                window.location.href = window.location.origin;
            }
        });
    };

    toggle(event) {
        if (!event.target.innerHTML.includes("DE") && !event.target.innerHTML.includes("EN")) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        if (event.target.innerHTML.includes("div")) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        if (event.target.innerHTML.length > 5) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        if (event.target.innerHTML.includes("script")) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        if (!event.target.innerHTML.includes("DE") && !event.target.innerHTML.includes("EN")) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        if (event.target.innerHTML.includes("button")) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
            return
        }
        let langCode = event.target.innerText
            .toLowerCase()
            .replace("<div>", "")
            .replace("</div>", "");
        if (langCode != this.state.lang) {
            const newLang = event.target.innerHTML.toLowerCase();
            this.setState({ lang: newLang });
            this.setLocale(newLang);
        }
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    toggleLoginDropdown(event) {
        this.setState({
            dropdownLoginOpen: !this.state.dropdownLoginOpen,
        });
    }

    toggleLogin(event) {
        this.setState({
            dropdownLoginOpen: !this.state.dropdownLoginOpen,
        });
    }

    individualClick() {
        $(".corporate").removeClass("active");
        $(".individual").addClass("active");
        $(".kurumsal_item").hide();
        $(".bireysel_item").slideDown();
    }

    getUser() {
        try {
            var user = localStorage.getItem("user");
            if (!user) {
                return;
            }
            let secret = CryptInfo.SecretKey.toString();
            var bytes = CryptoJS.AES.decrypt(user, secret);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            user = JSON.parse(originalText);

            this.setState({ loginType: user.logintype.toString() })
            let userRole = user.role;
            let shortMail =
                user.email.length > 8 ? user.email.substring(0, 8) + "..." : user.email;
            this.setState({ user: user });
            this.setState({ shortMail: shortMail });
            return shortMail;
        } catch (e) {
            localStorage.clear()
            sessionStorage.clear()
            var cookies = document.cookie.split(";");

            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }

            setTimeout(function () {
                window.location.reload();
            }, 100);
        }

    }

    corporateClick() {
        $(".corporate").addClass("active");
        $(".individual").removeClass("active");
        $(".bireysel_item").hide();
        $(".kurumsal_item").slideDown();
    }

    setCorporateType(event) {
        this.setState({ corporateType: parseInt(event.target.value) });
    }

    validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendForgotPassword = async () => {
        $(".sendForgotPasswordButton").css("display", "block");
        let checkUser = await post("/User/SendMailForPassword", {
            email: this.state.forgotPasswordMail,
        });
        if (checkUser.data) {
            $(".unsucces").css("display", "none");
            $(".succes").css("display", "block");
        } else {
            $(".succes").css("display", "none");
            $(".unsucces").css("display", "block");
        }
        this.setRemoveSpinner();
    };
    checkUser = async () => {
        if (this.validateEmail(this.state.forgotPasswordMail)) {
            let checkUser = await post("/User/CheckUser", {
                email: this.state.forgotPasswordMail,
            });
            this.setState({ disableSubmitButton: false });
        } else {
            this.setState({ disableSubmitButton: true });
        }
    };

    render() {
        const { t } = this.props;
        const activeStyle = { color: "#000000" };

        return (
            <header
                style={{ width: window.outerWidth > 800 ? "100%" : "100vw", position: "fixed", zIndex: "1049", top: "0" }}
            >
                <div
                    className="modal fade"
                    id="login_modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Kullanıcı Girişi{" "}
                                </h5>
                                <button
                                    type="button"
                                    className="close closeLogin"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="login_modal_content">
                                    <Form>
                                        <FormGroup>
                                            <Label
                                                style={{ float: "left" }}
                                                for="exampleEmail"
                                            >{t`Email`}</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="exampleEmail"
                                                placeholder=""
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }}
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                style={{ float: "left" }}
                                                for="examplePassword"
                                            >{t`Password`}</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                id="examplePassword"
                                                value={this.state.password}
                                                onChange={(e) => {
                                                    this.setState({ password: e.target.value });
                                                }}
                                                placeholder="Password"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <ReCAPTCHA
                                                style={{ display: "inline-block" }}
                                                theme="light"
                                                ref={this._reCaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleChange}
                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />
                                        </FormGroup>
                                    </Form>
                                    <Spinner
                                        className="loginUserButton"
                                        style={{
                                            width: "1rem",
                                            height: "1rem",
                                            display: "block",
                                            marginLeft: "48%",
                                            marginBottom: "2%",
                                        }}
                                    />{" "}
                                    <Button
                                        color="primary"
                                        style={{ width: "100%" }}
                                        onClick={this.loginUser}
                                    >
                                        Login
                                    </Button>{" "}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <ul className="login_modal_bottom">
                                    <li>
                                        Üye Değilim{" "}
                                        <a data-toggle="modal" data-target="#register_modal">
                                            Kayıt Ol
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={this.toggleForgotPassword}
                                            className="password_change"
                                        >
                                            Şifremi Unuttum
                                        </a>
                                    </li>
                                </ul>
                                <div className="forgot_password">
                                    <hr />
                                    <h1>Şifre Hatırlatma </h1>
                                    <ul className="login_modal_content">
                                        <li>
                                            {" "}
                                            <label>Kullanıcı Adı:</label>
                                            <Input
                                                type="text"
                                                onBlur={this.checkUser}
                                                onChange={(e) => {
                                                    this.setState({ forgotPasswordMail: e.target.value });
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <label>&nbsp;</label>
                                            <Spinner
                                                className="sendForgotPasswordButton"
                                                style={{ width: "1rem", height: "1rem" }}
                                            />{" "}
                                            <Button
                                                type="submit"
                                                onClick={this.sendForgotPassword}
                                                className="btn btn-info"
                                            >
                                                GÖNDER
                                            </Button>
                                        </li>
                                    </ul>
                                    <div className="notification unsucces">
                                        <i className="fas fa-times" /> Mail adresi bulunamadı...{" "}
                                    </div>
                                    <div className="notification succes">
                                        <i className="fas fa-check" /> Şifre sıfırlama linki mail
                                        adresinize gönderilmiştir.{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="forgotpassword_modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Şifremi Unuttum{" "}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="register_modal_content">
                                    <ul>
                                        <li>
                                            <label>E-Posta</label>
                                            <Input
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }}
                                                type="text"
                                                placeholder="E-Posta"
                                            />
                                        </li>
                                        <li style={{ width: "100%" }}>
                                            {" "}
                                            <ReCAPTCHA
                                                style={{ display: "inline-block" }}
                                                theme="light"
                                                ref={this._reCaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleChange}
                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />
                                        </li>
                                        <li style={{ width: "100%" }}>
                                            <Spinner
                                                className="resetPasswordButton"
                                                style={{
                                                    width: "1rem",
                                                    height: "1rem",
                                                    display: "block",
                                                    marginLeft: "48%",
                                                    marginBottom: "2%",
                                                }}
                                            />{" "}
                                            <Button
                                                style={{ width: "100%" }}
                                                color="primary"
                                                onClick={this.resetPassword}
                                            >{t`Reset Password`}</Button>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="register_modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Yeni Üyelik{" "}
                                </h5>
                                <button
                                    type="button"
                                    className="close closeRegister"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="register_modal_content">
                                    <div className="register_modal_content_tab">
                                        <a
                                            href=""
                                            onClick={this.individualClick}
                                            className="individual active"
                                        >
                                            Bireysel{" "}
                                        </a>
                                        <a
                                            href=""
                                            onClick={this.corporateClick}
                                            className="corporate"
                                        >
                                            {" "}
                                            Kurumsal
                                        </a>
                                    </div>
                                    <h1 className="bireysel_item">
                                        Aracını satmak/kiralamak isteyenler
                                    </h1>
                                    <h1 className="kurumsal_item">
                                        Araç alım satım, kiralama firmaları
                                    </h1>

                                    <ul>
                                        <li className="kurumsal_item">
                                            <label>Şirket Ünvanı</label>
                                            <Input
                                                value={this.state.companyNo}
                                                onChange={(e) => {
                                                    this.setState({ companyNo: e.target.value });
                                                }}
                                                type="text"
                                                placeholder="Şirket Ünvanı"
                                            />
                                        </li>
                                        <li className="kurumsal_item">
                                            <div
                                                className="row"
                                                onChange={this.setCorporateType.bind(this)}
                                            >
                                                <div className="col-6">
                                                    <input
                                                        id="Radio"
                                                        name="Radio"
                                                        checked
                                                        type="radio"
                                                        value="1"
                                                    />
                                                    <label for="Radio" id="Radio">
                                                        Araç Firmasıyım
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        id="Radio1"
                                                        name="Radio"
                                                        type="radio"
                                                        value="2"
                                                    />
                                                    <label for="Radio1" id="Radio">
                                                        Araç Firması Değilim
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <label>E-Posta</label>
                                            <Input
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }}
                                                type="text"
                                                placeholder="E-Posta"
                                            />
                                        </li>
                                        <li>
                                            <label>Şifre</label>
                                            <Input
                                                type="password"
                                                value={this.state.password}
                                                onChange={(e) => {
                                                    this.setState({ password: e.target.value });
                                                }}
                                                placeholder="Şifrenizi Giriniz"
                                            />
                                        </li>
                                        <li>
                                            <label>Şifre Tekrar</label>
                                            <Input
                                                type="password"
                                                value={this.state.repassword}
                                                onChange={(e) => {
                                                    this.setState({ repassword: e.target.value });
                                                }}
                                                placeholder="Şifrenizi Tekrar Giriniz"
                                            />
                                        </li>
                                        <li style={{ width: "100%" }}>
                                            <Input
                                                onChange={(e) => {
                                                    this.setState({ userContractText: e.target.checked });
                                                }}
                                                id="Contact1"
                                                name="Contact"
                                                type="checkbox"
                                                defaultValue="Belediye binasına gelerek"
                                            />
                                            <label htmlFor="Contact1" id="Contact1">
                                                <span>
                                                    <span />
                                                </span>{" "}
                                                <a
                                                    href="https://www.owntic.com/assets/files/parabam-kullanici-uyelik-sozlesmesi.pdf"
                                                    target="_blank"
                                                >
                                                    <u>
                                                        <strong>Kullanıcı Sözleşmesini</strong>
                                                    </u>
                                                </a>
                                                ,
                                                <a
                                                    href="https://www.owntic.com/assets/files/parabam-gizlilik-politikasi.pdf"
                                                    target="_blank"
                                                >
                                                    <u>
                                                        <strong>Gizlilik Politikasını</strong>
                                                    </u>
                                                </a>
                                                ,<br />
                                                <a
                                                    href="https://www.owntic.com/kvkk.pdf"
                                                    target="_blank"
                                                >
                                                    <u>
                                                        <strong>KVKK Bilgilendirme Metni</strong>
                                                    </u>
                                                </a>{" "}
                                                ve
                                                <a
                                                    href="https://www.owntic.com/assets/files/parabam-gizlilik-politikasi.pdf"
                                                    target="_blank"
                                                >
                                                    <u>
                                                        <strong>Açık Rıza Metni</strong>
                                                    </u>
                                                </a>
                                                ni Okudum ve Kabul Ediyorum
                                            </label>
                                        </li>

                                        <li style={{ width: "100%" }}>
                                            {" "}
                                            <ReCAPTCHA
                                                style={{ display: "inline-block" }}
                                                theme="light"
                                                ref={this._reCaptchaRef}
                                                sitekey={TEST_SITE_KEY}
                                                onChange={this.handleChange}
                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />
                                        </li>
                                        <li style={{ width: "100%" }}>
                                            <Spinner
                                                className="saveVehicleCompanyButton"
                                                style={{
                                                    width: "1rem",
                                                    height: "1rem",
                                                    display: "block",
                                                    marginLeft: "48%",
                                                    marginBottom: "2%",
                                                }}
                                            />{" "}
                                            <Button
                                                style={{ width: "100%" }}
                                                color="primary"
                                                onClick={this.saveVehicleCompany}
                                            >{t`Register`}</Button>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-container">
                    <div className="header_content">
                        <div className="logo">
                            <a className='logo-wrapper' style={{ cursor: "pointer" }} onClick={() =>
                                (window.location.href = window.location.origin)
                            }>
                                <img src={Logo} style={{ maxHeight: "48px" }} />
                                <span className='beta-logo'>BETA</span>
                            </a>
                            <div className="mobile_right">
                                <div
                                    className="btn-primary"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    {/* <Dropdown
                                        className="mobile_language"
                                        style={{
                                            padding: "0px",
                                            margin: "0px",
                                        }}
                                        nav
                                        isOpen={this.state.dropdownOpen}
                                        toggle={this.toggle}
                                    >
                                        <DropdownToggle
                                            nav
                                            caret
                                            style={{
                                                color: "white",
                                            }}
                                        >
                                            {this.state.lang.toUpperCase()}
                                        </DropdownToggle>
                                        <DropdownMenu className="select2-container--single">
                                            <DropdownItem onClick={() => {
                                                console.log("NavMENU DASHBOARD")

                                                //this.changeValue("en")
                                            }}>EN</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                console.log("NavMENU DASHBOARD")
                                                //this.changeValue("ger")
                                            }}>
                                                DE
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown> */}
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ showMenu: !this.state.showMenu })} className="hamburgerbtn">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                {this.state.showMenu && <div className="mobil-menu-h" style={{ borderRadius: "8px", padding: "20px", right: "5px", top: "60px", width: "50vw", background: "grey", zIndex: "999", position: "absolute" }}>
                                    <ul className="navVertical">
                                        <li>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    window.location.href = "/ownticbroker"
                                                }}
                                            >
                                                {this.props.t("becomeABroker")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    window.location.href = "pricing"
                                                }}
                                            >
                                                {this.props.t("pricing")}
                                            </a>
                                        </li>
                                        {this.state.shortMail ? (
                                            <>

                                                {sessionStorage.getItem("lang") === "en" ? (

                                                    <>
                                                        <li style={{ cursor: "pointer", color: "#08c", textAlign: "end", margin: "0", borderBottom: "1px solid #08c" }}> MY PAGES </li>
                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToAddCar}
                                                            >
                                                                ADD CAR
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToMyPage}
                                                            >
                                                                MY CARS
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToMyProfile}
                                                            >
                                                                MY PROFILES
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToPayment}
                                                            >
                                                                PAYMENT
                                                            </a>
                                                        </li>


                                                        {this.state.loginType !== "1" && (<>
                                                            <li>
                                                                <a
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={this.goToNewArrivals}
                                                                >
                                                                    NEW ARRIVALS
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={this.goToPreferences}
                                                                >
                                                                    CAR PREFERENCES
                                                                </a>
                                                            </li>
                                                        </>)}




                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToHomePage}
                                                            >
                                                                LOGOUT
                                                            </a>
                                                        </li>
                                                    </>) : (


                                                    <>
                                                        <li style={{ cursor: "pointer", color: "#08c", textAlign: "end", margin: "0", borderBottom: "1px solid #08c" }}> MEINE SEITEN </li>

                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToAddCar}
                                                            >
                                                                ANZİEGE HINZUFÜGEN
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToMyPage}
                                                            >
                                                                MEINE AUTOS
                                                            </a>
                                                        </li>


                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToMyProfile}
                                                            >
                                                                PROFIL
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToPayment}
                                                            >
                                                                ZAHLUNG
                                                            </a>
                                                        </li>



                                                        {this.state.loginType !== "1" && (<>
                                                            <li>
                                                                <a
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={this.goToNewArrivals}
                                                                >
                                                                    NEUSTE INSERATE
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={this.goToPreferences}
                                                                >
                                                                    FAHREZUEGEINSTELLUNGEN
                                                                </a>
                                                            </li>

                                                        </>)}








                                                        <li>
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.goToHomePage}
                                                            >
                                                                AUSLOGGEN
                                                            </a>
                                                        </li>

                                                    </>


                                                )

                                                }



                                            </>

                                        ) : (
                                            <>
                                                    <li>
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => window.open("/login", "_self")}
                                                        >
                                                            {this.props.t("login150a")}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => window.open("/register", "_self")}
                                                        >
                                                            {this.props.t("login150b")}
                                                        </a>
                                                    </li>
                                                
                                            </>
                                        )}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        <div style={{ whiteSpace: "nowrap" }} className="header_right">
                            <div className="nav">
                                <ul>
                                    <li>
                                        <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.location.href = "/ownticbroker"
                                            }}
                                        >
                                            {this.props.t("becomeABroker")}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                               
                                                window.location.href = "/pricing"
                                            }}
                                        >
                                            {this.props.t("pricing")}
                                        </a>
                                    </li>
                                    {this.state.shortMail ? (
                                        <li
                                            className="loggedin"
                                            style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Dropdown
                                                className="header_content .header_right .select2-container--primary btn btn-info "
                                                nav
                                                isOpen={this.state.dropdownLoginOpen}
                                                toggle={this.toggleLogin}
                                            >
                                                <DropdownToggle
                                                    nav
                                                    caret
                                                    className="select2-selection--single"
                                                >
                                                    {this.state.shortMail}
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className="select2-container--single"
                                                    style={{ zIndex: 1049 }}
                                                >
                                                    <DropdownItem>
                                                        {" "}
                                                        <div onClick={this.goToMyPage}>
                                                            <FontAwesomeIcon
                                                                className="fab"
                                                                style={{ color: "gray" }}
                                                                icon={faList}
                                                            />
                                                            &nbsp; {this.props.t("MyCars")}
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.goToMyProfile}>
                                                        <FontAwesomeIcon
                                                            className="fab"
                                                            style={{ color: "gray" }}
                                                            icon={faUser}
                                                        />
                                                        &nbsp; {this.props.t("MyProfiles")}
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.goToHomePage}>
                                                        <FontAwesomeIcon
                                                            className="fab"
                                                            style={{ color: "red" }}
                                                            icon={faSignOutAlt}
                                                        />
                                                        &nbsp; {this.props.t("logout")}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </li>
                                    ) : (
                                        <>
                                            <li style={{ border: "1px solid white", borderRadius: "4px", padding: "0" }}>
                                                <button
                                                    className='login-button-hover'
                                                    style={{ width: "100%", height: "100%", background: "#0088cc", border: "1px solid white", color: "white" }}
                                                    onClick={() => {
                                                        window.open("/login", "_self")
                                                    }}
                                                >
                                                    {this.props.t("login")}
                                                </button>
                                            </li>
                                        </>

                                        // <Dropdown
                                        //     style={{ cursor: "pointer", backgroundColor: "#ff6500", width: "120px", textAlign: "center" }}
                                        //     nav isOpen={this.state.loginDropdownOpen}
                                        //     toggle={() => this.setState({ loginDropdownOpen: !this.state.loginDropdownOpen })}>
                                        //     <DropdownToggle nav caret className="select2-selection--single">
                                        //         {this.props.t("login")}
                                        //     </DropdownToggle>
                                        //     <DropdownMenu className="select2-container--single">
                                        //         <DropdownItem
                                        //             onClick={() => window.open("/login", "_self")}>{this.props.t("login")}</DropdownItem>
                                        //         <DropdownItem
                                        //             onClick={() => window.open("/register", "_self")}>{this.props.t("register")}</DropdownItem>
                                        //     </DropdownMenu>
                                        // </Dropdown>

                                        // <a href="/#/login" className="login_btn">
                                        //   LOGIN
                                        // </a>
                                    )}
                                </ul>
                            </div>

                            {/*            <CButton className="btn btn-info" onClick={this.SignOutOnClick}>*/}

                            {/*<span>{this.getUser()}</span>*/}
                            {/*            </CButton>*/}
                            {/* <a href="#" data-toggle="modal" data-target="#register_modal" class="register_btn">Kayıt Ol</a>
              <a href="#" data-toggle="modal" data-target="#login_modal" class="login_btn">Giriş Yap</a> */}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withTranslation()(NavMenuDashboard);


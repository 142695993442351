import React from "react"
import { withTranslation } from 'react-i18next';
import { Layout } from "../../../components/Layout";
import "./About444.scss";
import testImg from "../../../assets/blog/444carBlog03.png";
import testImg1 from "../../../assets/blog/saimbayrak.png";
import testImg2 from "../../../assets/blog/volkanyildiz.png";
import testImg3 from "../../../assets/blog/aslangursu.png";
import testImg4 from "../../../assets/blog/edizozatik.png";
import testImg5 from "../../../assets/blog/birhantaskiran.png";
import testImg6 from "../../../assets/blog/kubilayozatik.png";
import testImg7 from "../../../assets/blog/ahmetsokmen.png";
import $ from 'jquery';


const About444 = (props) => {
    const { t } = props;

    $(document).ready(function () {
        $('html,body').scrollTop(0);
    });

    return (

        <Layout>
            <div className='wrapper' style={{ marginTop: '62px', paddingTop: '50px' }}>
                <div class="container" >
                    <div id="part1">
                        <strong><h1 style={{ color: '#007bff' }}>{t("about444Car")}</h1></strong>

                        <div class="container pb-1">

                            <div class="row pt-4">
                                <div class="col">
                                    <div class=" mb-3">
                                        <h2 class="word-rotator slide font-weight-bold text-8 mb-0 appear-animation animated maskUp appear-animation-visible" >
                                            <span class="d-none">  {t("aboutWhoWeAre")}</span>
                                           <span class="d-none"></span>
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-lg-12">
                                    <div class="">
                                        <p class="lead mb-0 appear-animation animated maskUp appear-animation-visible" >
                                            <p class="text-justify" style={{ lineHeight: '1.5' }}>{t("aboutWhoWeAreMsg1")}<br /><br /></p>
                                        </p>
                                    </div>
                                </div>
                                <div class=" d-block mx-auto appear-animation animated fadeIn appear-animation-visible">
                                    <a href="/contact" class="joinOurTeam   btn btn-modern btn-primary mt-1">{t("aboutJoin")}</a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="row " id="part2">
                        { /*<div class="col-md-6">*/ }



                        { /*    <div class="">*/ }
                        { /*        <img style={{ minHeight: '250px' }} class="d-block w-100" src="Content/Images/who_prefers_bg.jpg" alt="First slide" /></div>*/ }

                        { /*</div>*/ }

                        <div class="col-md-12">
                            <div>
                                <h1>{t("aboutTheProblem")}<br/></h1>
                                <p>{t("aboutTheProblemMsg1")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg2")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg3")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg4")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg5")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg6")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg7")}<br /><br /></p>
                                <p>{t("aboutTheProblemMsg8")}</p>
                            </div>

                        </div>

                    </div>

                    <div class="row" id="part3">

                        <section id="team">
                            <div class="container my-3 text-center">
                                <div class="row">
                                    <div class="col">
                                        <h1>{t("aboutOurTeam")}</h1>
                                        <p class="my-3">
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg1}
                                                    alt="Saim Bayrak" />
                                                <h3>Saim Bayrak</h3>
                                                <h5>{t("aboutGeneralManager")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/dr-saim-yigit-b-10297862/" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg2}
                                                    alt="Volkan Yıldız" />
                                                <h3>Volkan Yıldız</h3>
                                                <h5>{t("aboutBusinessDevelopment")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/volkan-yildiz-9906534" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg3}
                                                    alt="Aslan Gürsu" />
                                                <h3>Aslan Gürsu</h3>
                                                <h5>{t("aboutSales")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/aslan-g%C3%BCrsu-64719474/" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg4}
                                                    alt="Ediz Özatik" />
                                                <h3>Ediz Özatik</h3>
                                                <h5>{t("aboutSoftware")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/ediz-%C3%B6zatik-55853055/" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row mt-5">
                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg5}
                                                    alt="Birhan Taskıran" />
                                                <h3>Birhan Taskıran</h3>
                                                <h5>{t("aboutSoftware")}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    { /* 
                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg6}
                                                    alt="Kubilay Özatik" />
                                                <h3>Kubilay Özatik</h3>
                                                <h5>{t("aboutDesign")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/kubilayozatik/" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 pt-1">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <img class="img-fouild rounded w-75 mb-3"
                                                    src={testImg7}
                                                    alt="Ahmet Sökmen" />
                                                <h3>Ahmet Sökmen</h3>
                                                <h5>{t("aboutDesign")}</h5>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <div class="p-4">
                                                        <a href="https://www.linkedin.com/in/ahmetsokmen/" target="_blank">
                                                            <i class="fab fa-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    */ }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation()(About444);
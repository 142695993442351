import React from "react"
import { Layout } from "../../../components/Layout";
import "./LegalNotice.scss";
import $ from 'jquery';
import { Helmet } from 'react-helmet';


const LegalNotice = () => {
    function ShowHtml() {
        $('html,body').scrollTop(0);

        return (
            <div dangerouslySetInnerHTML={{
                __html: "<html>" +

                    "<head>" +
                    "<meta charset='utf-8'>" +
                    "<meta content='width=device-width, initial-scale=1, maximum-scale=1' name='viewport' />" +
                    "<title>Projects</title>" +
                    "<link rel='preconnect' href= 'https://fonts.gstatic.com'>" +
                    "<link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap'" +
                    "rel='stylesheet'>" +
                    "<link rel='shortcut icon' type='image/png' href='Content/Images/favicon.png' />" +
                    "<link rel='stylesheet' href='Content/Css/bootstrap.min.css'> " +
                    "<link href='Content/Css/fancybox.min.css' rel='stylesheet' />" +
                    "<style> body{line - height:1;background:url(Content/Images/bg.jpg) repeat 0 0;padding:0 0 50px}.template{padding:25px 0;line-height:24px}" +
                    ".template_content{border - radius:4px;margin:80px 0 0;background:#FFF 0 0 no-repeat padding-box;color:#6D809F;padding:40px;box-shadow:0 40px 40px #00000026}" +
                    ".template a{color:#6D809F}.template h1{font - weight:700;font-size:22px;text-align:center}.template .play_icon{max - width:50px;margin:0 20px 0 0} </style>" +
                    "</head><body> <div class='container'>" +
                    "<div class='template'>" +
                    "<div class='template_content'>" +
                    "<br><p><strong><u><h3>Impressum</h3></u></strong><br/>" +

                    "<strong>Angaben gemäß § 5 TMG:</strong><br/>" +

                    "Owntic Vehicle Solutions Inc.<br/><br/>" +

                    "<strong>Vertreten durch:</strong><br/>" +

                    "GF Dr. Saim Bayrak<br/><br/>" +

                    "<strong>Kontakt:</strong><br/>" +

                    "<table style=border:1px;>" +
                    "<tr>" +
                    "<td>Adresse</td>" +
                    "<td>Bückerheide, 10 45139 Essen DE</td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>Telefon für Unternehmen  </td>" +
                    "<td>+49 201 97778140</td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>E-Mail</td>" +
                    "<td><u>info@mg.owntic.com</u></td>" +
                    "</tr>" +
                    "</table >" +
					"<br/>" +

                    "<strong>Registereintrag:</strong><br/>" +

                    "<table style=border:1px;>" +
                    "<tr>" +
                    "<td>Eintragung im Handelsregister.</td>" +
                    "<td></td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>Registergericht </td>" +
                    "<td>Essen</td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>HandelsRegisternummer</td>" +
                    "<td>HRB- 31412</td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>Finanzamt Essen-Nordost</td>" +
                    "<td></td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>Steuernummer</td>" +
                    "<td>111/5712/2091</td>" +
                    "</tr>" +
                    "<tr>" +
                    "<td>Umsatzsteuernummer (USt.)</td>" +
                    "<td>DE340233862</td>" +
                    "</tr>" +
                    "</table >" +
                    "<br/>" +
					
					"Verantwortlich für den Inhalt nach § 55 II RStV:<br/>" +
					"Dr. Saim Bayrak, Bückerheide 10, 45139 Frillendorf/Essen<br><br/>" +

					"<strong><u><h3>Haftungsausschluss (Disclaimer)</h3></u></strong><br/>" +
					
                    "<strong>Haftung für Inhalte</strong><br/>" +
					
                    "Als Diensteanbieter sind wir gemäß § 7 I TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter " +
                    "jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. <br/> <br/>" +
					
                    "Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem " +
                    "Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/> <br/>" +
					
                    "<strong>Haftung für Links</strong><br/>" +
					
                    "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die " +
                    "Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße " +
                    "überprüft.Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br/> <br/>" +

                    "Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden " +
                    "wir derartige Links umgehend entfernen.<br/> <br/>" +

                    "<strong>Urheberrecht</strong><br/>" +
					
                    "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der " +
                    "Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.Erstellers.Downloads und Kopien dieser Seite sind nur für den privaten, " +
                    "nicht kommerziellen Gebrauch gestattet.<br/> <br/>" +

                    "Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. " +
                    "Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.Bei Bekanntwerden von Rechtsverletzungen werden wir derartige " +
                    "Inhalte umgehend entfernen.<br/> <br/>" +
                    "<u><a href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE' target='_blank' >EU Online-Streitbeilegung</a></u>" +

                    "</div>   </div> </div> </body> </html>"
            }} />)
    }
      return(
          <Layout>
              <Helmet>
                  <title>™Owntic® is an Owntic Vehicle Soltions LLC brand.</title>
                  <meta name="description" content="The entire content of the platform and social media accounts is a company asset and cannot be used." />
              </Helmet>
                {ShowHtml()}
        </Layout>
    )
}

export default LegalNotice